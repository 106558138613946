import React, { useState, useEffect, useRef } from 'react';
import {Button } from "reactstrap";

import Navbar from '../Navbar/Navbar';
import Breadcrumb from '../Breadcrumb/Breadcrumb';
import Preview from '../Preview/Preview';
import Footer from "../Footer/Footer";


import './Forms.css';
import parse from 'html-react-parser';

import * as Hosts from '../../../Hosts';
import {
    set_language,
    reqGET,
	reqPOST,
    repo_banner_link,
    content_language

} from '../../../Utils';


import { language } from '../WEBContent/Language';

import Swal from 'sweetalert2';

const ContactForm = (props) => {

    let preview_id = props.info.state.preview;

    const [lang, set_lang] = useState('pt');
    const [lang_id, set_lang_id] = useState('1');
    const [data, setData] = useState([]);   
    
    const [is_preview, set_is_preview] = useState(false);   

    let getInfo = async () => {

        reqGET(`${Hosts.SIMCore_API}/web/content/${Hosts.companycode}/97`)
            .then(res => { 
                setData(res.data)
                //console.log('CONTACTOS', res.data)

            })
            .catch(erro => alert('Erro'))  

    }    
    useEffect(() => { 

        //let preview_id = props.info.state.preview;
        if(preview_id !== undefined) {
            set_is_preview(true)
        }

        getInfo()
        if(localStorage.getItem('lang') === undefined  || localStorage.getItem('lang') === null || localStorage.getItem('lang') === '') {
            set_lang('pt')
            set_language('pt')
            set_lang_id('1')
        }
        else {
            set_lang(localStorage.getItem('lang'))
            content_language.forEach(el => {
                if(el.code === localStorage.getItem('lang')) {
                    set_lang_id(el.id)
                }
            })
        }
    
    }, [preview_id]); 

    const [agree, setAgree] = useState(false);

    const checkboxHandler = () => {
      // if agree === true, it will be set to false
      // if agree === false, it will be set to true
      setAgree(!agree);
      // Don't miss the exclamation mark
    }

    const checkboxHandler_reload = (agree) => {
        //console.log('AGREE', agree)
        if (agree === true)
            return true
        else 
            return false
        // if agree === false, it will be set to true
        //setAgree(!agree);
        // Don't miss the exclamation mark
      }

    const data_interface = {
        id: '',
        info: {
            companycode: Hosts.companycode,
            name: '',
            email: '',
            subject: '',
            message: '',
            rgpd: false,    
        }

    }

    const [ , set_dados ] = useState( null ) ;
    const dados = useRef( data_interface ) ;

    const [counterText, setCounterText] = useState('');



    const handle_change_info = (e) => {
		const { name, value } = e.target;

		set_dados(dados.current.info[name] = value)
        //console.log('Dados Current:' + JSON.stringify(dados))
    }

    const handle_change_info_textarea = (e) => {
        const { name, value } = e.target;
        //console.log('NAME ', name)
        //console.log('VALUE ', value)
        setCounterText(e.target.value)

        set_dados(dados.current.info[name] = value)
        //set_dados(dados.current.info.date.creation = new Date())
        //console.log('Dados Current:' + JSON.stringify(dados))
    }
    
   /*  const handleSubmit = async e => {
        e.preventDefault();

        //alert('SUBMIT');
        //return;
        
        reqPOST(`${Hosts.SIMCore_API}/entities`, dados.current)
            .then(() => {   
                alert('Registo adicionado!')
                //props.history.push(endpoints.list) 
            })
            .catch(() => alert('Problema ao atualizar Registo!'))
    } */


    let mailOptions = {
        from: Hosts.contactFrom,
        // vários emails separados por virgulas
        to: dados.current.info.email,
        //to: 'suporte@assec.pt',

        //cc-> opcional
        //cc: Hosts.contactFrom,
        bcc: Hosts.contactFrom,
        subject: Hosts.webSiteTitle + ' - ' + language[lang].contact_form,
        //text: 'Teste de EMAIL ', -> opcional
        html:
        '<html><head><link rel="preconnect" href="https://fonts.googleapis.com"><link rel="preconnect" href="https://fonts.gstatic.com" crossorigin><link href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,700;1,400;1,700&display=swap" rel="stylesheet"> </head><body style="background-color:' + Hosts.EMAIL_BODY_BACKGROUND_COLOR +'; font-family:poppins, helvetica, arial, sans-serif; ">' +
            '<div style="width:100% !important; margin-top:0; margin-bottom:0; margin-right:0; margin-left:0; padding-top:0; padding-bottom:0; padding-right:0; padding-left:0; color:' + Hosts.EMAIL_TEXT_COLOR +';">' +
            '<table border="0" align="center" cellpadding="0" cellspacing="0" style="margin-top:0; margin-bottom:0; padding-top:0; padding-bottom:0; padding-right:0; padding-left:0; border-collapse:collapse;">' +
                    '<tr style="border-collapse: collapse;">' +
                        '<td style="border-collapse: collapse; font-family:poppins, helvetica, arial, sans-serif; font-size:' + Hosts.EMAIL_TEXT_SIZE +'; line-height:' + Hosts.EMAIL_TEXT_LINE_HEIGHT  +';">' +
                            '<table width="600" cellpadding="0" cellspacing="0" border="0" align="0" class="0" style="width: 600px; background-color:' + Hosts.EMAIL_BACKGROUND_COLOR +'; border:1px solid transparent; border-collapse:collapse;">' +
                                '<tr style="border-collapse: collapse;">' +
                                    '<td align="left" class="logo" style="padding:0; border-collapse:collapse;">' +
                                        '<a alt="' + Hosts.webSiteTitle + '" title="' + Hosts.webSiteTitle + '" href="' + Hosts.EMAIL_WEB_SITE_URL + '" target="_blank">' +
                                            '<img style="width: 600px; " src="' + repo_banner_link("banner-email.jpg") + '"/>' +
                                        '</a>'+
                                    '</td>' +
                                '</tr>' +
                            '</table>' +
                            '<table width="600" cellpadding="0" cellspacing="0" border="0" align="0" class="0" style="width: 600px; height: 90px; padding: 15px 30px; background-color:' + Hosts.EMAIL_BACKGROUND_COLOR +'; border:1px solid transparent; border-collapse:collapse;">' +
                                '<tr style="border-collapse: collapse;">' +
                                    '<td align="left" style="padding:40px; border-collapse:collapse; font-family:poppins, helvetica, arial, sans-serif; font-size:' + Hosts.EMAIL_TEXT_SIZE +'; line-height:' + Hosts.EMAIL_TEXT_LINE_HEIGHT  +'; color:' + Hosts.EMAIL_TEXT_COLOR +'">' +
                                        '<div>'+
                                            '<span>'+ dados.current.info.name + language[lang].email_contact +'</span>'+
                                            '<br/>'+
                                            '<br/>'+
                                            '<span><b>'+ language[lang].name +': </b><label>' + dados.current.info.name + '</label></span>' +
                                            '<br/>'+
                                            '<span><b>Email: </b><label>' + dados.current.info.email + '</label></span>'+
                                            '<br/>'+
                                            '<span><b>'+ language[lang].subject +': </b><label>' + dados.current.info.subject + '</label></span>' +
                                            '<br/>'+
                                            '<br/>'+
                                            '<span><label>' + dados.current.info.message.replaceAll('\n', '<br/>') + '</label></span>' +
                                            '<br/>'+
                                        '</div>'+
                                    '</td>' +
                                '</tr>' +
                            '</table>' +
                            '<table width="600" cellpadding="0" cellspacing="0" border="0" align="0" class="0" style="width: 600px; background-color:' + Hosts.FOOTER_EMAIL_COLOR +'; padding: 10px; border: 1px solid transparent; border-collapse:collapse;">' +
                                '<tr style="border-collapse: collapse;">' +
                                    '<td align="left" style="padding-bottom: 10px; padding-top: 10px; border-collapse:collapse; font-family:poppins, helvetica, arial, sans-serif; font-size:' + Hosts.FOOTER_EMAIL_TEXT_SIZE +'; line-height:' + Hosts.FOOTER_EMAIL_TEX_LINE_HEIGHT  +';">' +
                                        '<div>'+
                                            '<div style="text-align: center; font-family:poppins, helvetica, arial, sans-serif; font-size:' + Hosts.FOOTER_EMAIL_TEXT_SIZE +'; line-height:' + Hosts.FOOTER_EMAIL_TEX_LINE_HEIGHT  +'; color:' + Hosts.FOOTER_EMAIL_TEXT_COLOR +';">'+
                                                '<span style="text-transform: uppercase;">' + Hosts.webSiteTitle + '</span>'+
                                                '<br/>'+
                                                '<span>' + Hosts.webSiteAdress + '</span>'+
                                                '<br/>'+
                                                '<a alt="' + Hosts.webSiteTitle + '" title="' + Hosts.webSiteTitle + '" href="' + Hosts.EMAIL_WEB_SITE_URL + '" target="_blank" style="font-family:poppins, helvetica, arial, sans-serif; font-size:' + Hosts.FOOTER_EMAIL_TEXT_SIZE +'; line-height:' + Hosts.FOOTER_EMAIL_TEX_LINE_HEIGHT  +';"> '+
                                                    '<span style="color:' + Hosts.FOOTER_EMAIL_TEXT_COLOR +';font-family:poppins, helvetica, arial, sans-serif; font-size:' + Hosts.FOOTER_EMAIL_TEXT_SIZE +'; line-height:' + Hosts.FOOTER_EMAIL_TEX_LINE_HEIGHT  +'; text-decoration: underline" >'+ Hosts.EMAIL_WEB_SITE_URL +'</span>' +
                                                '</a>'+
                                            '</div>'+
                                        '</div>' +
                                    '</td>' +
                                '</tr>' +
                            '</table>' +
                        '</td>' +
                    '</tr>' +
                '</table>' +
            '</div>' +
        '<body><html>',
        //attachments -> opcional 
        // attachments: [     
        //  {   // encoded string em Base 64
        //  filename: 'text1.txt',
        //  content: 'aGVsbG8gd29ybGQh',
        //  encoding: 'base64'
        //  },
        // ]
    
        };
    


    const handleSubmit = async e => {
        e.preventDefault();

        //alert('SUBMIT');
        //return;

        reqPOST(`${Hosts.SIMCore_API_MAIL}`, {data: mailOptions})
            .then(() => { 
                //console.log('Notificações enviadas com sucesso') 
                set_dados(dados.current = JSON.parse(JSON.stringify(data_interface)))
                document.getElementById('agree').checked = false
                setAgree(false)
                //setRefresh(refresh + 1)
                Swal.fire({
                    title: language[lang].contact_form,
                    text: language[lang].sent_successfully,
                    icon: 'success',
                    //showCancelButton: true,
                    confirmButtonColor: '#08396a',
                    //confirmButtonText: 'Tentar novamente',
                  })
        
            })
            .catch((error) => console.log(error)/*alert('Problema ao enviar notificações!')*/)

    }

        
    return(
        <>
            {
            is_preview ? 
                <Preview />
            : 
                null
            }
            <Navbar />
            <Breadcrumb />
            <section className="margemBody mt-5 aa">                    
                        
                {/* <div className="container-fluid bg-color-blue">
                    <div className="container py-4 mb-4">
                        <h3 className="titulo-primario">{language[lang].contacts}</h3>
                    </div></div> */}

                <div className="container mb-5">
                    <form onSubmit={handleSubmit}>                                                

                        <div className="row">
                        <div className="col-12 col-lg-6 pr-lg-5">
                            {
                                data.map((v, key) => {
                                    return (
                                    <div key={key}>
                                        <div>
                                            <h3 className="titulo-primario">
                                            {lang === 'pt' || v.content[0].language === undefined ? v.content[0].title : (v.content[0].language[lang_id] === undefined || v.content[0].language[lang_id].title === undefined || v.content[0].language[lang_id].title === '' ? v.content[0].title : v.content[0].language[lang_id].title)}
                                            </h3>
                                            <p className="subtitulo-secundario">
                                            {lang === 'pt' || v.content[0].language === undefined ? v.content[0].subtitle : (v.content[0].language[lang_id] === undefined || v.content[0].language[lang_id].subtitle === undefined || v.content[0].language[lang_id].subtitle === '' ? v.content[0].subtitle : v.content[0].language[lang_id].subtitle)}
                                            </p>
                                        </div>
                                        <div>
                                            {parse(lang === 'pt' || v.content[0].language === undefined ? v.content[0].text : (v.content[0].language[lang_id] === undefined || v.content[0].language[lang_id].text === undefined || v.content[0].language[lang_id].text === '' ? v.content[0].text : v.content[0].language[lang_id].text))}
                                        </div>
                                    </div>
                                    )
                                })
                            }
                        </div>
                        <div className="col-12 col-lg-6 mt-3 mt-md-0">
                            <div className="row">
                                <div className="col-12">
                                    <p>
                                        {language[lang].read_terms_conditions}
                                        {/*Leia os nossos <a href={Hosts.WEB_SITE_URI + "terms_conditions"} target="_blank" rel="noreferrer">termos e condições</a> antes de submeter o formulário. Ao submeter concorda com os termos do nosso serviço.*/}
                                    </p>
                                </div>
                                
                                <div className="col-12 col-md-6 form-group">
                                    <label>{language[lang].name}*</label>
                                    <input type="text" name="name" value={dados.current.info.name} className="form-control" placeholder="" onChange={handle_change_info} required />
                                </div>
                                <div className="col-12 col-md-6 form-group">
                                    <label>{language[lang].email}*</label>
                                    <input type="email" name="email" 
                                        value={dados.current.info.email} 
                                        className="form-control" 
                                        placeholder="" 
                                        onChange={handle_change_info} required
                                    /> 
                                </div>
                                
                                <div className="col-12 form-group">
                                    <label>{language[lang].subject}*</label>
                                    <input type="subject" name="subject" 
                                        value={dados.current.info.subject} 
                                        className="form-control" 
                                        placeholder="" 
                                        onChange={handle_change_info} required
                                    /> 
                                </div>  
                                <div className="col-12 form-group">
                                    <label>{language[lang].message}*</label>
                                    <textarea
                                        style={{ height: "180px", width: "100%" }}
                                        id="message"
                                        name="message"
                                        maxLength="1200"
                                        value={dados.current.info.message}
                                        onChange={handle_change_info_textarea}
                                        required
                                    />
                                    <p className="text-right my-2 small">{language[lang].maximum_numbers_of_caracters}&#58;&nbsp;{counterText.length}/1200</p>
                                    {/* <TextareaCounter countLimit={1200} style={{height: "100px", width: "100%"}} id="message" name="message" value={dados.current.info.message} onChange={handle_change_info} required/>
                                    <input type="text" name="message" value={dados.current.info.message} className="form-control" placeholder="" onChange={handle_change_info} /> */}
                                </div>

                                <div className="col-12">
                                    <input type="checkbox" id="agree" onChange={checkboxHandler} defaultChecked={checkboxHandler_reload(agree)}/>
                                    <label className="pl-2" htmlFor="agree">{language[lang].agree_terms_conditions}</label> <a href={Hosts.WEB_SITE_URI + "terms-conditions"} target="_blank" rel="noreferrer">{language[lang].terms_conditions_link}</a>
                                    <label className="pl-2 small" htmlFor="agree">{language[lang].terms_conditions_required}</label>
                                </div>
                            </div>
                        </div>
                            
                        </div>

                        <div className="row">
                            
                        </div>                            
                    
                        <div className="text-right">
                            {/*{console.log('AGREE2', agree)}
                            {console.log('MAIL: ', JSON.stringify(mailOptions))}*/}
                            <Button disabled={!agree} className="mt-2 mb-md-5 mx-auto" color="primary">{language[lang].send}</Button>     
                        </div>                              

                    </form>
                </div>

                <div className="mapouter" style={{lineHeight: 0}}>
                    <div className="gmap_canvas">
                        {/* <iframe width="100%" height="500" id="gmap_canvas" src={"https://maps.google.com/maps?q=Rua%20da%20Estrada%20Nacional%20339,%20Apartado%20332&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=&amp;output=embed"} frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"></iframe> */}
                        <iframe width="100%" height="500" id="gmap_canvas" title="gmap canvas" src="https://www.google.com/maps/d/embed?mid=1Y0lzvJOFWpq7GRJ15RS4P9N1Tk8z1XVI&ehbc=2E312F" frameBorder="0" scrolling="no" allowFullScreen={true} loading="lazy" referrerPolicy="no-referrer-when-downgrade" />

                        
                    </div>
                </div>
                    
            </section>
            <Footer />
        </>
    );


};

export default ContactForm;