import React, { useState, useEffect } from 'react';

import { Redirect, Route, Switch } from 'react-router-dom';
//import {useLocation } from 'react-router-dom';

import './App.css';

//import Login from './components/Login/Login';

import useToken from './UseToken';

import Pagina from './components/Pagina/Pagina';
//import AppForm from './components/Pagina/Forms/AppForm';

//import Contact from "./components/Pagina/Forms/ContactForm";

/*import politicaPrivacidade from "./components/Pagina/Conteudo/politicaPrivacidade"; */
/* import termosCondicoes from "./components/Pagina/Conteudo/termosCondicoes"; */

import Cookies from "./components/Pagina/Cookies/Cookies";

import Content from './components/Pagina/Content/Content';


import Loader from './loader'

//import CookieConsent from "react-cookie-consent";


import * as Hosts from './Hosts';

import "../src/components/Pagina/Pagina.css";

import {
  repo_favicon_link,
  set_language,
} from './Utils';

//if (process.env.REACT_APP_MODE === 'production') console.log = function () { };
function App() {

  const { setToken } = useToken();

  const handleFavIcon = () => {

    function getFaviconEl() {
      return document.getElementById("favicon");
    }
    const favicon = getFaviconEl(); // Accessing favicon element
    //console.log('FavICON', repo_favicon_link("favicon.ico"))
    //console.log('FavICON get', document.getElementById("favicon"))
    favicon.href = repo_favicon_link("favicon.ico");
    //favicon.href = "favicon.ico";
  };

  // 
  //document.title = `${document.title}-Scientia`
  handleFavIcon()

  //const page_location = useLocation().pathname.split('/').pop();

  //const [lang, set_lang] = useState('pt');

  //const menus = [];
  //const { sideBarData, setSideBarData, getSidebar } = useSidebar();


  //const [menus, setMenus] = useState([])

  //const [isloading, setLoading] = useState(true);

  //const [isloading, setLoading] = useState(true);

  //LOADER TIMEOUT
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    window.process = {
      ...window.process,
    };
  }, []);



  



  useEffect(() => {
    if(localStorage.getItem('lang') === undefined || localStorage.getItem('lang') === null) {
      set_language('pt')
    }

    setToken({ "token_site": Hosts.SITES.SIMCONFERENCE.ACCESS_TOKEN })

    const timer = setTimeout(() => {
      setIsLoading(false);
      //setCount('Timeout called!');
    }, 500);

    return () => clearTimeout(timer);

    /*    setTimeout(() => {
        setIsLoading(false);
      }, 500);  */
  }, [setToken])


  /*useEffect(() => {
    setToken({ "token_site": Hosts.SITES.SIMCONFERENCE.ACCESS_TOKEN })
    //console.log('token_site', Hosts.SITES.SIMCONFERENCE.ACCESS_TOKEN)

    /* setTimeout(() => {
    setLoading(false)
    /* })

  }, [])*/


  return isLoading ?
    <main>
      <Loader />
    </main>
    :
    <>
      <Cookies />




      <>


        {/* <Navbar /> */}
        {/*  <Pagina /> */}
        <Switch >

          <Route path='/' exact component={Pagina} />
          {/* <Route path="/app_form" exact component={AppForm} /> */}

          {/* <Route path={"/terms_conditions"} exact component={termosCondicoes} />
          <Route path={"/privacy_policy"} exact component={termosCondicoes} />
          <Route path={"/contact"} exact component={Contact} /> */}


          <Route path={"/pag/:pag_id"} component={Content} exact={true} />
          <Route path={"/pag/:pag_id/:subpage_id"} component={Content} exact={true} />

          <Route path={"/article/:article_id"} component={Content} exact={true} />
          <Route path={"/insight/:insight_id"} component={Content} exact={true} />
          <Route path={"/partner/:partner_id"} component={Content} exact={true} />
          <Route path={"/gallery/:gallery_id"} component={Content} exact={true} />

          <Route path={"/routes/:routes_id"} component={Content} exact={true} />
          <Route path={"/interest/:interest_id"} component={Content} exact={true} />

          <Route path={"/form/:form_slug"} component={Content} exact={true} />

          <Route path={"/preview/:preview_id"} component={Content} exact={true} />


          <Route path={"/:slug"} component={Content} exact={true} />


          {/*   <Route exact path={Hosts.WEB_SITE_URI + "contacts"} component={Contact} /> */}

          {/*  <Route path={Hosts.WEB_SITE_URI + "terms_conditions"} exact component={termosCondicoes} />
          <Route path={Hosts.WEB_SITE_URI + "privacy_policy"} exact component={politicaPrivacidade} /> */}

          <Redirect to="/" />


        </Switch >
      </>



    </>


}

export default App;

