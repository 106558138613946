import React, { useState, useEffect } from 'react';
// import { useLocation } from 'react-router-dom';


import ReactHtmlParser/*, { processNodes, convertNodeToElement, htmlparser2 }*/ from 'react-html-parser';
//import { Helmet } from 'react-helmet';

import * as Hosts from '../../../Hosts';
import { reqGET } from '../../../Utils';

import Loader from '../../../loader';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import Preview from '../Preview/Preview';
import Breadcrumb from '../Breadcrumb/Breadcrumb';

export default function Routes(props) {
    // const { pathname } = useLocation();

    const [isloading, setLoading] = useState(true);

    const data_interface = {
        id: '',
        info: {},
        content: {}
    }
    const [content, set_content] = useState(data_interface);

    useEffect(() => {
        try {
			let id = props.info.state.id;
			let slug = props.info.state.slug;
			let preview_id = props.info.state.preview;

            if(id !== '') {
        
                let getPreviewInfoId = async () => {
                    await reqGET(`${Hosts.SIMCore_API}/web/content/${Hosts.companycode}/${preview_id}`)
                        .then(res => { 
                            if(res.data.length > 0) {
                                res.data[0].info['preview'] = true

                                var hoje = new Date();
                                let status = 0
                                if(res.data[0].info.date.published !== undefined && res.data[0].info.date.published !== '') {
                                    var date_p = new Date(res.data[0].info.date.published);
        
                                    status = 1
                                    if(hoje >= date_p) 
                                        status = 2
                                }
       
                                if(res.data[0].info.preview === true) {
                                    //res.data[0].content = {text: 'preview'}
                                    console.log('Normal Preview')
        
                                    set_content(res.data[0])
                                    setLoading(false)   
                                }
                                else if(status !== 2) {
                                    res.data[0].content = {text: 'rascunho ou agendado'}
        
                                    set_content(res.data[0])
                                    setLoading(false)    
                                }
                                else {
                                    set_content(res.data[0]) 

                                    setLoading(false) 
                                }
                            }
                        })
                        .catch(erro => console.log('ERROR', erro))
                }

                let getInfoId = async () => {
                    await reqGET(`${Hosts.SIMCore_API}/web/content/${Hosts.companycode}/${id}`)
                        .then(res => { 
                            if(res.data.length > 0) {
        
                                var hoje = new Date();
                                let status = 0
                                if(res.data[0].info.date.published !== undefined && res.data[0].info.date.published !== '') {
                                    var date_p = new Date(res.data[0].info.date.published);
        
                                    status = 1
                                    if(hoje >= date_p) 
                                        status = 2
                                }
        
                                if(status !== 2) {
                                    res.data[0].content = {text: 'rascunho ou agendado'}
        
                                    set_content(res.data[0])
                                    setLoading(false)    
                                }
                                else {
                                    set_content(res.data[0]) 
            
                                    setLoading(false) 
                                }
                            }
                        })
                        .catch(erro => console.log('ERROR', erro))
                }
            
                let getInfoSlug = async () => {
                    reqGET(`${Hosts.SIMCore_API}/web/content/slug/${Hosts.companycode}/${slug}`)
                        .then(res => { 
                            if(res.data.length > 0) {
        
                                var hoje = new Date();
                                let status = 0
                                if(res.data[0].info.date.published !== undefined && res.data[0].info.date.published !== '') {
                                    var date_p = new Date(res.data[0].info.date.published);
        
                                    status = 1
                                    if(hoje >= date_p) 
                                        status = 2
                                }
        
                                if(status !== 2)  {
                                    res.data[0].content = [{text: 'rascunho ou agendado'}]
        
                                    set_content(res.data[0])
                                    setLoading(false)
                                }
                                else {        
                                    set_content(res.data[0]) 
        
                                    setLoading(false) 
                                }
                            }
                        })
                        .catch(erro => console.log('ERROR', erro))
                }
        
                if(preview_id !== undefined && preview_id !== 'undefined' && preview_id !== '') {
                    getPreviewInfoId()
                }
                else if(id !== undefined && id !== 'undefined' && id !== '') {
                    getInfoId()
                }
                else if(slug !== undefined && slug !== 'undefined' && slug !== '')  {
                    getInfoSlug()
                }
            }
            else {
                console.log('=== ""')
            }

            return

        } catch {
            console.log('nada')

			return
		}
        
    }, [props.info.state.id, props.info.state.preview, props.info.state.slug]);

    return (isloading ?

		<Loader />
		
		:

        <>
            {
            content.info['preview'] === true ? 
                <Preview />
            : 
                null
            }
            <Navbar />
            <Breadcrumb />
            <main className="article">
                <div>
                    {/* <Helmet>
                        <title>{content.info.seo !== undefined && content.info.seo['title'] !== undefined ? content.info.seo['title'] : Hosts.SITES.SIMWEBSITE.DEFAULT_SEO_TITLE}</title>
                        <meta name="description" content={content.info.seo !== undefined && content.info.seo['description'] !== undefined ? content.info.seo['description'] : Hosts.SITES.SIMWEBSITE.DEFAULT_SEO_DESCRIPTION} />
                        <meta name="keywords" content={content.info.seo !== undefined && content.info.seo['keywords'] !== undefined ? content.info.seo['keywords'] : Hosts.SITES.SIMWEBSITE.DEFAULT_SEO_KEYWORDS} />
                    </Helmet> */}
                </div>

                {
                content.content.map((el, k) => {  
                    return (
                        <section key={k} className="content-body">
                            <div className="container">
                                <div className="row">
                                    {
                                    el['media'] !== undefined && el['media']['image'] !== undefined && el['media']['image'].length > 0 ?
                                        <>
                                            <div className="div-content col-12 col-sm-9">
                                                <div className={"div-title"}>
                                                    <h1 className={el.title !== '' ? "titulo-primario" : "d-none"}>{el.title}</h1>
                                                </div>

                                                <div className={"div-abstract mt-3"}>
                                                    <p>{ReactHtmlParser(el.abstract)}</p>
                                                </div>

                                                <div className="div-categories w-100"><i className="bi bi-tag" />
                                                    {
                                                    content.info.tags.map((v, k) => {
                                                        return (
                                                            <span key={k} className="pill-tag">{v.text}</span>
                                                        )
                                                    })
                                                    }
                                                </div>

                                                <div className={"div-image mt-3"}>
                                                    <img src={el['media']['image'][0]['link']} className="img-fluid" alt="" />
                                                </div>
                                                
                                                <div className={"div-text mt-5"}>
                                                    {ReactHtmlParser(el.text)}
                                                </div>
                                            </div>
                                        </>
                                    : 
                                        <>
                                            <div id="div-content" className="div-content col-12 col-sm-9">
                                                <div className={"div-title"}>
                                                    <h2 className={el.title !== '' ? "title" : "d-none"}>{el.title}</h2>
                                                </div>

                                                <div className="div-categories w-100"><i className="bi bi-tag" />
                                                    {
                                                    content.info.tags.map((v, k) => {
                                                        return (
                                                            <span key={k} className="pill-tag">{v.text}</span>
                                                        )
                                                    })
                                                    }
                                                </div>

                                                <div className={"div-abstract mt-5"}>
                                                    {ReactHtmlParser(el.abstract)}
                                                </div>

                                                <div className="div-text mt-5">
                                                    {ReactHtmlParser(el.text)}
                                                </div>
                                            </div>
                                        </>
                                    }

                                    {
                                    el['media'] !== undefined && el['media']['image'] !== undefined && el.media.image.length > 1 ?
                                        <div className="div-content col-12 mb-5">
                                            <div className="row div-gallery">
                                                {
                                                el.media.image.map((el_img, k_img) => {
                                                    if(k_img > 0) {
                                                        return (
                                                            <div key={k_img} className="col-2">
                                                                <figure>
                                                                    <img className="img-fluid" src={el_img.link} alt={el_img.description} />
                                                                    <figcaption>{el_img.description}</figcaption>
                                                                </figure>
                                                            </div>
                                                        )
                                                    }
                                                    else
                                                        return null
                                                })
                                                }
                                            </div>
                                        </div>
                                    :
                                        <></>
                                    }
                                    {
                                    el['media'] !== undefined && el['media']['doc'] !== undefined && el.media.doc.length > 0 ?
                                        <div className="div-content col-12 mb-5">
                                            <div className="row div-documents">
                                                {
                                                el.media.doc.map((el_doc, k_doc) => {
                                                    return (
                                                        <div key={k_doc} className="mb-2">
                                                            <img className="img-fluid" src={el_doc.link} alt="" />
                                                            <a className="btn btn-primary" href={el_doc.link} target="_blank" rel="noreferrer">{el_doc.name}</a>
                                                        </div>
                                                    )
                                                })
                                                }
                                            </div>
                                        </div>
                                    :
                                        <></>
                                    }
                                </div>
                            </div>
                        </section>
                    )
                })
                }
            </main>
            <Footer />
        </>
    )
}