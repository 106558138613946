import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';


import ReactHtmlParser/*, { processNodes, convertNodeToElement, htmlparser2 }*/ from 'react-html-parser';
import { Helmet } from 'react-helmet';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";

import * as Hosts from '../../../Hosts';
import { reqGET } from '../../../Utils';

import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import Loader from '../../../loader';
import Preview from '../Preview/Preview';
import Breadcrumb from '../Breadcrumb/Breadcrumb';

import AssecImage from "../ASSEC_Image/Image"

export default function List(props) {
    const { pathname } = useLocation();

    const [isloading, setLoading] = useState(true);

    const data_interface = {
        id: '',
        info: {},
        content: {}
    }
    const [content, set_content] = useState(data_interface);
    const [subpage_id, set_subpageid] = useState('');

    useEffect(() => {
        try {
			let id = props.info.state.id;
			let slug = props.info.state.slug;
			let subpage = props.info.state.subpage;

            set_subpageid(subpage)

            if(id !== '') {
        
                let getInfoId = async () => {
                    await reqGET(`${Hosts.SIMCore_API}/web/content/${Hosts.companycode}/${id}`)
                        .then(res => { 
                            if(res.data.length > 0) {
        
                                var hoje = new Date();
                                let status = 0
                                if(res.data[0].info.date.published !== undefined && res.data[0].info.date.published !== '') {
                                    var date_p = new Date(res.data[0].info.date.published);
        
                                    status = 1
                                    if(hoje >= date_p) 
                                        status = 2
                                }
        
                                if(status !== 2) {
                                    res.data[0].content = {text: 'rascunho ou agendado'}
        
                                    set_content(res.data[0])
                                    setLoading(false)    
                                }
                                else {
                                    set_content(res.data[0]) 
            
                                    setLoading(false) 
                                }
                            }
                        })
                        .catch(erro => console.log('ERROR', erro))
                }
            
                let getInfoSlug = async () => {
                    reqGET(`${Hosts.SIMCore_API}/web/content/slug/${Hosts.companycode}/${slug}`)
                        .then(res => { 
                            if(res.data.length > 0) {
        
                                var hoje = new Date();
                                let status = 0
                                if(res.data[0].info.date.published !== undefined && res.data[0].info.date.published !== '') {
                                    var date_p = new Date(res.data[0].info.date.published);
        
                                    status = 1
                                    if(hoje >= date_p) 
                                        status = 2
                                }
        
                                if(status !== 2)  {
                                    res.data[0].content = [{text: 'rascunho ou agendado'}]
        
                                    set_content(res.data[0])
                                    setLoading(false)
                                }
                                else {
                                    set_content(res.data[0]) 
        
                                    setLoading(false) 
                                }
                            }
                        })
                        .catch(erro => console.log('ERROR', erro))
                }
        
                if(id !== undefined && id !== 'undefined' && id !== '') {
                    getInfoId()
                }
                else if(slug !== undefined && slug !== 'undefined' && slug !== '')  {
                    getInfoSlug()
                }
            }
            else {
                console.log('=== ""')
            }

            return

        } catch {
            console.log('nada')

			return
		}
        
    }, [props.info.state.subpage, props.info.state.id, props.info.state.preview, props.info.state.slug]);


    const get_seo_data = (type) => {

        if(type === 'title') {
            if(content.info.seo !== undefined && content.info.seo['title'] !== undefined  && content.info.seo['title'] !== '') {
                return content.info.seo['title']
            } 
            else {
                return Hosts.SITES.SIMWEBSITE.DEFAULT_SEO_TITLE
            }
        }
        else if(type === 'description') {
            if(content.info.seo !== undefined && content.info.seo['description'] !== undefined  && content.info.seo['description'] !== '') {
                return content.info.seo['description']
            } 
            else if(content.content !== undefined && content.content.length > 0 && content.content[0]['abstract'] !== undefined && content.content[0]['abstract'] !== undefined  && content.content[0]['abstract'] !== '') {
                return content.content[0]['abstract']
            } 
            else {
                return Hosts.SITES.SIMWEBSITE.DEFAULT_SEO_DESCRIPTION
            }
        }
        if(type === 'keywords') {
            if(content.info.seo !== undefined && content.info.seo['keywords'] !== undefined  && content.info.seo['keywords'] !== '') {
                return content.info.seo['keywords']
            } 
            else {
                return Hosts.SITES.SIMWEBSITE.DEFAULT_SEO_KEYWORDS
            }
        }
        else if(type === 'image') {
            if(content.content !== undefined && content.content.length > 0 && content.content[0]['media'] !== undefined && content.content[0]['media']['image'] !== undefined && content.content[0]['media']['image'].length > 0 && content.content[0]['media']['image'][0]['link'] !== '') {
                return content.content[0]['media']['image'][0]['link']
            } 
            else {
                return Hosts.SITES.SIMWEBSITE.DEFAULT_SEO_IMAGE
            }
        }
        else if(type === 'link') {
            let title_seo = Hosts.WEB_SITE_URI + pathname
            return '.' + title_seo.replace("//", "/");
        }
    }

    return (isloading ?

		<Loader />
		
		:
        <>
            {
            content.info['preview'] === true ? 
                <Preview />
            : 
                null
            }
            <Navbar />
            <Breadcrumb />
            <main className="subpage mt-3 mb-3 mb-md-5">
                <div>
                    <Helmet>
                        <title>{ get_seo_data('title') }</title>
                        <link rel="canonical" href={ get_seo_data('link') } />
                        <meta name="description" content={ get_seo_data('description') } />
                        <meta name="keywords" content={ get_seo_data('keywords') } />
                        <meta property="og:url" content={ get_seo_data('link') } />
                        <meta property="og:title" content={ get_seo_data('title') }></meta>
                        <meta property="og:description" content={ get_seo_data('description') }></meta>
                        <meta property="og:image" content={ get_seo_data('image') }></meta>
                        <meta name="twitter:title" content={ get_seo_data('title') }></meta>
                        <meta name="twitter:description" content={ get_seo_data('description') }></meta>
                        <meta name="twitter:image" content={ get_seo_data('image') }></meta>
                    </Helmet>
                </div>

                <section className="content-body">
                    <div className="container">
                        <div className="row">
                        	<div className="col-12">
		                        {
		                        content.content.map((el, k) => {  
		                            if(k === parseInt(subpage_id)) {
		                                return (
		                                    <section key={k} className="content-body mt-3">
		                                        <div className="container">
		                                            <div className="row">
		                                                {
		                                                el['media'] !== undefined && el['media']['image'] !== undefined && el['media']['image'].length > 0 ?
		                                                    <>
		                                                        <div className="div-image col-12">
                                                                    <AssecImage 
                                                                        linkImage={el['media']['image'][0]['link']}
                                                                        width="1280"
                                                                        height="720"
                                                                        fit='crop'
                                                                        type='img'
                                                                        sClass="img-fluid ms-5" 
                                                                        alt=""
                                                                    />
		                                                            <img src={el['media']['image'][0]['link']} className="img-fluid ms-5" alt="" />
		                                                        </div>
		            
		                                                        <div className="div-content col-12">
		                                                            <h2 className={el.title !== '' ? "titulo-primario mt-5 mb-5" : "d-none"}>{el.title}</h2>
		                                                            <h2 className={el.subtitle !== '' ? "titulo-secundario mb-5" : "d-none"}>{el.subtitle}</h2>
		                                                            <div className={"div-text"}>
		                                                                {ReactHtmlParser(el.text)}
		                                                            </div>
		                                                        </div>
		                                                    </>
		                                                : 
		                                                    <>
		                                                        <div id="div-content" className="div-content col-12 mb-5">
		                                                            <h2 className={el.title !== '' ? "titulo-primario mb-5" : "d-none"}>{el.title}</h2>
		                                                            <h2 className={el.subtitle !== '' ? "titulo-secundario mb-5" : "d-none"}>{el.subtitle}</h2>
		                                                            <div className="div-text">
		                                                                {ReactHtmlParser(el.text)}
		                                                            </div>
		                                                        </div>
		                                                    </>
		                                                }
		            
		                                                {
		                                                el['media'] !== undefined && el['media']['image'] !== undefined && el.media.image.length > 1 ?
		                                                    <div className="div-content col-12 my-5">
		                                                        <div className="row div-gallery">
		                                                            {
		                                                            el.media.image.slice(1).map((el_img, k_img) => {
		                                                                return (
		                                                                    <div key={k_img} className="col-2">
		                                                                        <figure>
                                                                                    <AssecImage 
                                                                                        linkImage={el_img.link}
                                                                                        width="450"
                                                                                        height="300"
                                                                                        fit='crop'
                                                                                        type='img'
                                                                                        sClass="img-fluid" 
                                                                                        alt={el_img.name}
                                                                                    />
		                                                                            {/*<img className="img-fluid" src={el_img.link} alt={el_img.description} title={el_img.description} />*/}
		                                                                            <figcaption>{el_img.description}</figcaption>
		                                                                        </figure>
		                                                                    </div>
		                                                                )
		                                                            })
		                                                            }
		                                                        </div>
		                                                    </div>
		                                                :
		                                                    <></>
		                                                }
		                                                {
		                                                el['media'] !== undefined && el['media']['doc'] !== undefined && el.media.doc.length > 0 ?
		                                                    <div className="div-content col-12 mb-5">
		                                                        <div className="row div-documents">
		                                                            {
		                                                            el.media.doc.map((el_doc, k_doc) => {
		                                                                return (
		                                                                    <div key={k_doc} className="col-12 mb-2">
		                                                                        {/* <img className="img-fluid" src={el_doc.link} title="" /> */}
		                                                                        
		                                                                        <a href={el_doc.link} rel="noreferrer" target="_blank">
		                                                                            <FontAwesomeIcon className="font-weight-small pr-2" icon={faFilePdf} fixedWidth />
		                                                                            {el_doc.name}
		                                                                        </a>
		                                                                    </div>
		                                                                )
		                                                            })
		                                                            }
		                                                        </div>
		                                                    </div>
		                                                :
		                                                    <></>
		                                                }
		                                            </div>
		                                        </div>
		                                    </section>
		                                )
		                            }
		                            else 
		                                return null
		                        })
		                        }
	                        </div>
                        </div>
                    </div>
                </section>

            </main>
            <Footer />
        </>
    )
}