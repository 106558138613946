import React, { useState, useEffect } from 'react';
import parse from 'html-react-parser';
import { useLocation } from 'react-router-dom';
import './Footer.css';

import { language } from '../WEBContent/Language';

import { reqGET, set_language, /*repo_logo_link,*/ repo_site_documents_link, repo_site_assets_link, content_language } from '../../../Utils';

import * as Hosts from '../../../Hosts';

import Loader from '../../../loader';

// import { facebook } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const Footer = () => {

  const [lang, set_lang] = useState(localStorage.getItem('lang'));
  const [/*lang_id*/, set_lang_id] = useState('1');

  const data_interface_navbar = {
    id: '',
    info: {},
    content: {
        childs: []
    }
  }

  const [navbar, set_navbar] = useState(data_interface_navbar);

  const [/*refresh*/, setRefresh] = useState(0);

  const [isloading, setLoading] = useState(true);

  const location = useLocation().pathname;

  // const change_lang = (l) => {
  //   set_language(l)
  //   set_lang(l)

  //   //console.log('language', localStorage.getItem('lang'))
  //   //console.log('localstorage', localStorage)
  // }

  useEffect(() => {

    if (localStorage.getItem('lang') === undefined || localStorage.getItem('lang') === null || localStorage.getItem('lang') === '') {
      set_lang('pt')
      set_language('pt')
      set_lang_id('1')
  }
  else {
      set_lang(localStorage.getItem('lang'))
      content_language.forEach(el => {
          if (el.code === localStorage.getItem('lang')) {
          set_lang_id(el.id)
          }
      })
  }

    let getInfoNavbar = async () => {

      await reqGET(`${Hosts.SIMCore_API}/web/structure/${Hosts.companycode}/1`)
        .then(res => {
            if(res.data.length > 0) {
                res.data.forEach((v) => {
                    if (v.info.principal === true) {
                        //set_dados(dados.current = v);

                        if(lang !== 'pt') {
                            let x = 0
                            content_language.forEach((cl) => {
                                if(cl.code === lang) {
                                    v.content.childs.forEach(async (el) => {
                                        if(el.id_content !== '') {
                                            if(el.children !== undefined) {
                                                x = x - el.children.length
                                                el.children.forEach(async (el_c) => {
                                                    await reqGET(`${Hosts.SIMCore_API}/web/content/${Hosts.companycode}/${el_c.id_content}`)
                                                        .then(res_3 => {
                                                            if(res_3.data.length > 0) {
                                                                if(res_3.data[0].info.language.length > 0) {
                                                                    if(res_3.data[0].info.language[cl.id] !== undefined && res_3.data[0].info.language[cl.id] !== null) {
                                                                        if(res_3.data[0].info.language[cl.id]['name'] !== '') {
                                                                            el_c.title = res_3.data[0].info.language[cl.id]['name'];
            
                                                                            x++
                                                                            if(v.content.childs.length === x) {
                                                                                setRefresh(refresh => refresh + 1)
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                                else {
                                                                    x++
                                                                    if(v.content.childs.length === x) {
                                                                        setRefresh(refresh => refresh + 1)
                                                                    }
                                                                } 
                                                            }
                                                            else {
                                                                x++
                                                                if(v.content.childs.length === x) {
                                                                    setRefresh(refresh => refresh + 1)
                                                                }
                                                        } 
                                                    })
                                                })
                                            }

                                            await reqGET(`${Hosts.SIMCore_API}/web/content/${Hosts.companycode}/${el.id_content}`)
                                                .then(res_2 => {
                                                    if(res_2.data.length > 0) {
                                                        if(res_2.data[0].info.language.length > 0) {
                                                            if(res_2.data[0].info.language[cl.id] !== undefined && res_2.data[0].info.language[cl.id] !== null) {
                                                                if(res_2.data[0].info.language[cl.id]['name'] !== '') {
                                                                    el.title = res_2.data[0].info.language[cl.id]['name'];
                                                                    
                                                                    x++
                                                                    if(v.content.childs.length === x) {
                                                                      setRefresh(refresh => refresh + 1)
                                                                    }
                                                                }
                                                            }
                                                        }
                                                        else {
                                                            x++
                                                            if(v.content.childs.length === x) {
                                                              setRefresh(refresh => refresh + 1)
                                                            }
                                                        }  
                                                    } 
                                                    else {
                                                        x++
                                                        if(v.content.childs.length === x) {
                                                          setRefresh(refresh => refresh + 1)
                                                        }
                                                    } 
                                                })

                                            await reqGET(`${Hosts.SIMCore_API}/web/content/${Hosts.companycode}/${el.id_content}`)
                                                .then(res_2 => {
                                                    if(res_2.data.length > 0) {
                                                        if(res_2.data[0].info.language.length > 0) {
                                                            if(res_2.data[0].info.language[cl.id] !== undefined && res_2.data[0].info.language[cl.id] !== null) {
                                                                if(res_2.data[0].info.language[cl.id]['name'] !== '') {
                                                                    el.title = res_2.data[0].info.language[cl.id]['name'];
      
                                                                    x++
                                                                    if(v.content.childs.length === x) {
                                                                      setRefresh(refresh => refresh + 1)
                                                                    }
                                                                }
                                                            }
                                                        }
                                                        else {
                                                            x++
                                                            if(v.content.childs.length === x) {
                                                              setRefresh(refresh => refresh + 1)
                                                            }
                                                        } 
                                                    }
                                                    else {
                                                        x++
                                                        if(v.content.childs.length === x) {
                                                          setRefresh(refresh => refresh + 1)
                                                        }
                                                    } 
                                                })

                                        }
                                        else {
                                            if(el['language'] !== undefined && el['language'] !== null) {
                                                if(el.language[cl.id] !== undefined && el.language[cl.id]['title'] !== undefined && el.language[cl.id]['title'] !== '') { 
                                                    el.title = el.language[cl.id]['title']
      
                                                    x++
                                                    if(v.content.childs.length === x) {
                                                      setRefresh(refresh => refresh + 1)
                                                    }
                                                }
                                            }
                                        }
                                    })
                                }
                            })
                        }

                        set_navbar(v);
                        setLoading(false)
                    }
                })
            }
            else {
                return []
            }

        })
        .catch(erro => console.log('ERROR', erro))
    }

    //set_navbar(navbar = { id: '', info: '', content: { childs: [] } })

    getInfoNavbar()

    // if (localStorage.getItem('lang') === undefined || localStorage.getItem('lang') === null || localStorage.getItem('lang') === '') {
    //   change_lang('pt')
    // }
    // else {
    //   set_lang(localStorage.getItem('lang'))
    // }


  }, [lang])


const page_location = useLocation().pathname.split('/').pop();

  return (

    isloading ? 
      <Loader />
    :
      <footer className="bg-color-body text-white">
        <div className="container d-print-none">
          <div className="row">
            <div className='col-12 text-center'>
              <a href={repo_site_documents_link('CENTRO-04-38D7-FEDER-001180.pdf')} target="_blank" rel="noreferrer" alt="Centro 2020" title="Centro 2020" >
                <img src={repo_site_assets_link("centro_2020_FEDER-branco.svg")} alt="Centro 2020" title="Centro 2020" className="img-fluid" />
              </a>
            </div>
          </div>
          <div className="row py-5 py-sm-5 flex-column flex-md-row justify-content-lg-between">
            <div className="col-12 col-md-3">
              {/* <img src={repo_logo_link("logo-branco.svg")} width="120px" height="75px" alt={Hosts.webSiteTitle} className="img-fluid pb-2" /> */}
              <span className="address">
                <h5 className="font-weight-bold">{parse(language[lang].footer_address_1)}</h5>
              </span>
              <span className="address mb-md-3">
                <img alt='' className="pr-2" height="20px" src={repo_site_assets_link("localizacao.svg")} />
                {parse(language[lang].footer_address_11)}
              </span>
              <span className="address mb-md-1">
                <img alt='' className="pr-2" height="20px" src={repo_site_assets_link("telefone.svg")} />
                <a href={'tel:' + language[lang].footer_address_12}>
                  {language[lang].footer_address_12}
                  <br></br>
                  <small>{language[lang].call_landline}</small>
                </a>
              </span>
              <span className="address mb-md-3">
                <img alt='' className="pr-2" height="20px" src={repo_site_assets_link("email.svg")} />
                <a href={'mailto:' + language[lang].footer_address_13}>{language[lang].footer_address_13}</a>
              </span>
              <span className="address">
                {language[lang].footer_address_14}
              </span>
            </div>
            <div className="col-12 col-md-4 mt-3 mt-md-0">
            {
              navbar.content.childs.map((v, key) => {

                //console.log('Menu Parents: ', v)

                if(v.id === 11 && v.children !== undefined && v.children !== '' && v.children.length > 0) { 

                  return (

                    <div key={key}>
                      <h5 className="font-weight-bold">{v.title}</h5>
                      {

                        v.children.map((v2, key2) => {

                          //console.log('Childrens: ', v2)

                          let link_content_dropdown_item = ''
                          
                          v2.id_content !== '' ?
                              link_content_dropdown_item = (v2.slug !== undefined && v2.slug !== '' ? Hosts.WEB_SITE_URI + '' + v2.slug : Hosts.WEB_SITE_URI + 'pag/' + v2.id_content)
                          :
                              link_content_dropdown_item = (v2.slug !== undefined && v2.slug !== '' ? Hosts.WEB_SITE_URI + '' + v2.slug : Hosts.WEB_SITE_URI + v2.link)


                          //let link_content_dropdown_item = (v2.slug !== undefined && v2.slug !== '' ? Hosts.WEB_SITE_URI + '' + v2.slug : Hosts.WEB_SITE_URI + 'pag/' + v2.id_content);
                          let current_top_dropdown = (v2.slug !== undefined && v2.slug !== '' ? v2.slug : v2.id_content);

                          if(current_top_dropdown === '') {
                            if(location === '/' + v2.link) {
                                current_top_dropdown = v2.link
                            }
                        }

                          return (

                            <div key={key2}>

                              <a href={link_content_dropdown_item} className={ ((page_location !== '' && page_location === current_top_dropdown) || (page_location !== '' && location === '/' + current_top_dropdown) ? 'current_bottom' : '')}>
                                  {v2.title}
                              </a>

                            </div>

                          )
                        })
                      }
                    </div>
                  )


                }
                else 
                  return null
              })
            }
            </div>
            <div className="d-none">
              <h5 className="font-weight-bold">Quick Links</h5>
              <div className="d-flex flex-column justify-content-start">
                  <span><i className="fa fa-angle-right mr-2 d-none"></i><a href="./turistrela">A Turistrela</a></span>
                  <span><i className="fa fa-angle-right mr-2 d-none"></i><a href="./destino-serra-da-estrela">Destino Serra da Estrela</a></span>
                  <span><i className="fa fa-angle-right mr-2 d-none"></i><a href="./estrategia-de-desenvolvimento-sustentavel">Estratégia de Desenvolvimento Sustentável</a></span>
                  <span><i className="fa fa-angle-right mr-2 d-none"></i><a href="./estrelanofuturo">Estrela no Futuro</a></span>
                  <span><i className="fa fa-angle-right mr-2 d-none"></i><a href="./partner/all">Parceiros de Negócios</a></span>
              </div>
            </div>
            <div className="col-12 col-md-3 mt-3 mt-md-0">
              <h5 className="font-weight-bold">{language[lang].legal_info}</h5>
              <div className="d-flex flex-column justify-content-start">
                  <span><i className="fa fa-angle-right mr-2 d-none"></i><a href="./termos-e-condicoes">{language[lang].terms_conditions}</a></span>
                  <span><i className="fa fa-angle-right mr-2 d-none"></i><a href="https://www.livroreclamacoes.pt/" target="_blank" rel="noreferrer">{language[lang].complaint_book}</a></span>
              </div>
            </div>
            <div className="col-12 col-md-2 mt-3 mt-md-0">
              <h5 className="font-weight-bold mb-4 d-none d-lg-block">{language[lang].footer_social}</h5>
              <span className="logosfooter redes my-3 my-md-0 align-items-center">
                <a href={Hosts.fbLink} title={Hosts.fbLink} alt={Hosts.fbLink} target="_blank" rel="noreferrer" >
                  <img src={repo_site_assets_link("facebook.svg")} width="" height="" alt={Hosts.fbLink} className="img-fluid" />
                </a>
                <a href={Hosts.instaLink} title={Hosts.instaLink} alt={Hosts.instaLink} target="_blank" rel="noreferrer" >
                  <img src={repo_site_assets_link("instagram.svg")} width="" height="" alt={Hosts.instaLink} className="img-fluid" />
                </a>
              </span>
            </div>
          </div>
        </div>
        <div className="container-fluid bg-color-dark text-white">
          <div className="row">
            <div className="container">
              <div className="row">
                <div className="col-12 footer-bar py-4">
                  <ul className="list-inline flex-column flex-sm-row text-center mb-0 copyright">
                    <li>
                      <span><i className="far fa-copyright pr-1"></i> 2022 <span style={{fontWeight: 600}} className="pl-1">{Hosts.webSiteTitle}</span>.</span>
                      <span>{language[lang].reserved_rights}</span>
                    </li>
                    <li>
                      <span>
                        {language[lang].developed} 
                        <a href="http://sim.assec.pt" title="Assec Sim!" alt="Assec Sim!" target="_blank" rel="noreferrer" className="pl-1">
                          {/*<img className="img-fluid" src="./img/logo.png" alt="Assec Sim!" style={logoAssec}/>*/}
                        Assec Sim!
                        </a>
                      </span>
                    </li>
                  </ul>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>

  )

};
export default Footer;