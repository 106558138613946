import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';


//import { Document, Page } from 'react-pdf';
//import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

import ReactHtmlParser/*, { processNodes, convertNodeToElement, htmlparser2 }*/ from 'react-html-parser';
import parse from 'html-react-parser';
//import { Helmet } from 'react-helmet';

import * as Hosts from '../../../Hosts';
import { reqGET, content_language, set_language } from '../../../Utils';

import Loader from '../../../loader';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import Breadcrumb from '../Breadcrumb/Breadcrumb';

import AssecImage from "../ASSEC_Image/Image"

import { language } from '../WEBContent/Language';

export default function InterestPointCards(props) {
    const { pathname } = useLocation();

    const [isloading, setLoading] = useState(true);

    const [lang, set_lang] = useState('pt');
    //const [lang_id, set_lang_id] = useState('1');
    const [, set_lang_id] = useState('1');

    const data_interface = {
        id: '',
        info: {},
        content: {}
    }
    //const [content, set_content] = useState(data_interface);
    const [content_parent, set_content_parent] = useState(data_interface);
    const [list_content, set_list_content] = useState([]);

    // const [numPages, setNumPages] = useState(null);
    // const [pageNumber, setPageNumber] = useState(1);
  
    // function onDocumentLoadSuccess({ numPages }) {
    //   setNumPages(numPages);
    // }

    useEffect(() => {
        /* console.log('aaaa') */
        if (localStorage.getItem('lang') === undefined || localStorage.getItem('lang') === null || localStorage.getItem('lang') === '') {
            set_lang('pt')
            set_language('pt')
            set_lang_id('1')
        }
        else {
            set_lang(localStorage.getItem('lang'))
            content_language.forEach(el => {
                if (el.code === localStorage.getItem('lang')) {
                set_lang_id(el.id)
                }
            })
            }

        try {
            let getInfoList = async () => {

                await reqGET(`${Hosts.SIMCore_API}/web/structure/${Hosts.companycode}/1`)
                    .then(res => {
                        if(res.data.length > 0) {
                            res.data.forEach((v) => {
                                if (v.info.principal === true) {
                                    //set_dados(dados.current = v);

                                    v.content.childs.forEach((el) => {
                                        
                                        if(el.id_content === '' && ('/' + el.link === pathname)) {
                                            if(el['parent'] !== undefined) {
                                                console.log(el['parent'])
                                            }
                                        }

                                        if(el['children'] !== undefined && el['children'].length > 0) {
                                            el['children'].forEach(async (el_c) => {
                                                if(el_c.id_content === '' && ('/' + el_c.link === pathname)) {
                                                    if(el_c['parent'] !== undefined) {

                                                        await reqGET(`${Hosts.SIMCore_API}/web/content/${Hosts.companycode}/${el_c['parent']}`)
                                                            .then(res => { 
                                                                if(res.data.length > 0) {
                                            
                                                                    var hoje = new Date();
                                                                    let status = 0
                                                                    if(res.data[0].info.date.published !== undefined && res.data[0].info.date.published !== '') {
                                                                        var date_p = new Date(res.data[0].info.date.published);
                                            
                                                                        status = 1
                                                                        if(hoje >= date_p) 
                                                                            status = 2
                                                                    }
                                            
                                                                    if(status !== 2) {
                                                                        res.data[0].content = {text: 'rascunho ou agendado'}
                                            
                                                                        set_content_parent(res.data[0])   
                                                                    }
                                                                    else {
                                                                        set_content_parent(res.data[0]) 
                                                                    }
                                                                }
                                                            })
                                                            .catch(erro => console.log('ERROR', erro))

                                                    }
                                                }
                                            })
                                        }

                                    })
                                    
                                }
                            })
                        }
                        else {
                            return []
                        }

                    })
                    .catch(erro => console.log('ERROR', erro))

                await reqGET(`${Hosts.SIMCore_API}/web/content/type/${Hosts.companycode}/interest`)
                    .then(res => { 
                        if (res.data.length > 0) {

                            var hoje = new Date();
                            let aux = []
                            res.data.forEach((v) => {
                              if(v.info.date.published !== undefined && v.info.date.published !== '') {
                                  var date_p = new Date(v.info.date.published);
                    
                                  if(hoje >= date_p) 
                                    aux.push(v)
                              }
                            })
                            set_list_content(aux)
                            //set_list_content(res.data)
                            //console.log('NOTICIAS', res.data)
                  
                            setLoading(false)
                          }
                    })
                    .catch(erro => console.log('ERROR', erro))
            }
            getInfoList()
 
            return

        } catch {
            console.log('nada')

			return
		}
        
    }, [pathname]);

    return (isloading ?

		<Loader />
		
		:
        <>
            <Navbar />
            <Breadcrumb />
            <main className="rinterest-point-cards">
                <section className="content-body my-5">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12 offset-md-1 col-md-10">
                                <div className="row">
                                 <div className="div-content col-12 mb-5">
                                        <h2 className="titulo-primario">{content_parent['content'][0] !== undefined && content_parent['content'][0]['title'] !== undefined && content_parent.content[0].title !== '' ? content_parent.content[0].title : 'Pontos de Interesse'}</h2>
                                        <h2 className={content_parent['content'][0] !== undefined && content_parent['content'][0]['subtitle'] !== undefined && content_parent.content[0].subtitle !== '' ? "titulo-secundario" : "d-none"}>{content_parent['content'][0] !== undefined && content_parent['content'][0]['subtitle'] !== undefined && content_parent.content[0].subtitle !== '' ? content_parent.content[0].subtitle : ''}</h2>
                                        <div className={"div-text"}>
                                            {content_parent['content'][0] !== undefined && content_parent['content'][0]['text'] !== undefined && content_parent.content[0].text !== '' ? ReactHtmlParser(content_parent.content[0].text) : ''}
                                        </div>
                                    </div>

                                    {
                                    list_content.map((content) => {
                                        return (
                                            content.content.map((el, k) => {  
                                                return (
                                                    <div key={k} className="col-12 col-md-6 col-lg-4 mb-5">
                                                        {
                                                        el['media'] !== undefined && el['media']['image'] !== undefined && el['media']['image'].length > 0 ?
                                                            <>
                                                                <div className="card interest-item hover-animate shadow-sm">
                                                                    <a href={'./article/' + content.id} title={language[lang].keep_reading}>
                                                                        <div className="div-image">
                                                                            <AssecImage 
                                                                                linkImage={el['media']['image'][0]['link']}
                                                                                width="450"
                                                                                height="300"
                                                                                fit='crop'
                                                                                type='img'
                                                                                sClass="img-fluid" 
                                                                                alt=''
                                                                            />
                                                                        </div>
                                                                    </a>
                                                                        
                                                                    <div className="card-body p-4">
                                                                        <span className="small d-none">{parse(content.info.date.published.split('T')[0])}</span>
                                                                        <h5 className="card-title">
                                                                            {el.title}
                                                                        </h5>
                                                                        <p className="card-text">
                                                                            {ReactHtmlParser(el.text)}
                                                                        </p>
                                                                    </div>
                                                                    <div className="px-3 mb-3 text-right">
                                                                        <a className="btn btn-text" href={'./article/' + content.id}>{language[lang].keep_reading}<i className="bi bi-chevron-right"></i></a>
                                                                    </div>

                                                                    <div className="card-footer text-muted d-none">
                                                                        <i className="bi bi-tag pr-2" />
                                                                        {
                                                                        content.info.tags.map((v, k) => {
                                                                            return (
                                                                                <span key={k} className="pill-tag">{v.text}</span>
                                                                                )
                                                                            })
                                                                        }
                                                                     </div>
                                                                </div>
                                                            </>
                                                        : 
                                                            <>
                                                                <div className="card">
                                                                    <div>
                                                                        <div className="card-body">
                                                                            <span className="small d-none">{parse(content.info.date.published.split('T')[0])}</span>
                                                                            <h5 className="subtitulo-primario">
                                                                                {el.title}
                                                                            </h5>
                                                                            <p className="card-text">
                                                                                {ReactHtmlParser(el.text)}
                                                                            </p>

                                                                        </div>
                                                                    </div>
                                                                    <div className="card-footer text-center">
                                                                        <a className="btn btn-footer-card" href={el.media.doc[0].link} target="_blank" rel="noreferrer">{language[lang].download_roteiro}<i className="bi bi-download pl-2" /></a>
                                                                    </div>

                                                                </div>
                                                            </>
                                                        }
                                                    </div> 
                                                )
                                            })
                                        )
                                    })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </>
    )
}