import React, { useState, useEffect, useRef } from 'react';
import {Button } from "reactstrap";

import '../Forms/Forms.css';
//import parse from 'html-react-parser'; 

import * as Hosts from '../../../Hosts';
import {
    set_language,
	reqPOST,
    reqFILE,
    repo_banner_link,
    content_language

} from '../../../Utils';


import { language } from '../WEBContent/Language';

import Swal from 'sweetalert2';

import Loader from '../../../loader';

const CareerForm = () => {

    const [lang, set_lang] = useState('pt');
    const [, set_lang_id] = useState('1');

    const [, set_files_doc] = useState([]);
    const [files_doc_name, set_files_doc_name] = useState([]);
    const [file_url, set_file_url] = useState('')
    const [is_uploading, /*set_is_uploading*/] = useState(' d-none')
    const [upload_file, set_upload_file] = useState()

    useEffect(() => { 
        
        if(localStorage.getItem('lang') === undefined  || localStorage.getItem('lang') === null || localStorage.getItem('lang') === '') {
            set_lang('pt')
            set_language('pt')
            set_lang_id('1')
        }
        else {
            set_lang(localStorage.getItem('lang'))
            content_language.forEach(el => {
                if(el.code === localStorage.getItem('lang')) {
                    set_lang_id(el.id)
                }
            })
        }
    
    }, []); 

    const [agree, setAgree] = useState(false);

    const checkboxHandler = () => {
      // if agree === true, it will be set to false
      // if agree === false, it will be set to true
      setAgree(!agree);
      // Don't miss the exclamation mark
    }

    const checkboxHandler_reload = (agree) => {
        //console.log('AGREE', agree)
        if (agree === true)
            return true
        else 
            return false
        // if agree === false, it will be set to true
        //setAgree(!agree);
        // Don't miss the exclamation mark
      }

    const data_interface = {
        id: '',
        info: {
            companycode: Hosts.companycode,
            name: '',
            email: '',
            subject: '',
            message: '',
            rgpd: false,    
        }

    }

    const [ , set_dados ] = useState( null ) ;
    const dados = useRef( data_interface ) ;

    const [counterText, setCounterText] = useState('');

    const handle_change_info = (e) => {
		const { name, value } = e.target;

		set_dados(dados.current.info[name] = value)
        //console.log('Dados Current:' + JSON.stringify(dados))
    }

    const handle_change_info_textarea = (e) => {
        const { name, value } = e.target;
        //console.log('NAME ', name)
        //console.log('VALUE ', value)
        setCounterText(e.target.value)

        set_dados(dados.current.info[name] = value)
        //set_dados(dados.current.info.date.creation = new Date())
        //console.log('Dados Current:' + JSON.stringify(dados))
    }

    let mailOptions = {
        from: Hosts.contactFrom,
        // vários emails separados por virgulas
        to: dados.current.info.email,
        //to: 'suporte@assec.pt',

        //cc-> opcional
        // cc: Hosts.contactFrom,
        bcc: Hosts.contactFrom,
        subject: Hosts.webSiteTitle + ' - ' + language[lang].contact_form,
        //text: 'Teste de EMAIL ', -> opcional
        html:
            '<html><head><link rel="preconnect" href="https://fonts.googleapis.com"><link rel="preconnect" href="https://fonts.gstatic.com" crossorigin><link href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,700;1,400;1,700&display=swap" rel="stylesheet"> </head><body style="background-color:' + Hosts.EMAIL_BODY_BACKGROUND_COLOR +'; font-family:poppins, helvetica, arial, sans-serif; ">' +
                '<div style="width:100% !important; margin-top:0; margin-bottom:0; margin-right:0; margin-left:0; padding-top:0; padding-bottom:0; padding-right:0; padding-left:0; color:' + Hosts.EMAIL_TEXT_COLOR +';">' +
                    '<table border="0" align="center" cellpadding="0" cellspacing="0" style="margin-top:0; margin-bottom:0; padding-top:0; padding-bottom:0; padding-right:0; padding-left:0; border-collapse:collapse;">' +
                        '<tr style="border-collapse: collapse;">' +
                            '<td style="border-collapse: collapse; font-family:poppins, helvetica, arial, sans-serif; font-size:' + Hosts.EMAIL_TEXT_SIZE +'; line-height:' + Hosts.EMAIL_TEXT_LINE_HEIGHT  +';">' +
                                '<table width="600" cellpadding="0" cellspacing="0" border="0" align="0" class="0" style="width: 600px; background-color:' + Hosts.EMAIL_BACKGROUND_COLOR +'; border:1px solid transparent; border-collapse:collapse;">' +
                                    '<tr style="border-collapse: collapse;">' +
                                        '<td align="left" class="logo" style="padding:0; border-collapse:collapse;">' +
                                            '<a alt="' + Hosts.webSiteTitle + '" title="' + Hosts.webSiteTitle + '" href="' + Hosts.EMAIL_WEB_SITE_URL + '" target="_blank">' +
                                                '<img style="width: 600px; " src="' + repo_banner_link("banner-email.jpg") + '"/>' +
                                            '</a>'+
                                        '</td>' +
                                    '</tr>' +
                                '</table>' +
                                '<table width="600" cellpadding="0" cellspacing="0" border="0" align="0" class="0" style="width: 600px; height: 90px; padding: 15px 30px; background-color:' + Hosts.EMAIL_BACKGROUND_COLOR +'; border:1px solid transparent; border-collapse:collapse;">' +
                                    '<tr style="border-collapse: collapse;">' +
                                        '<td align="left" style="padding:40px; border-collapse:collapse; font-family:poppins, helvetica, arial, sans-serif; font-size:' + Hosts.EMAIL_TEXT_SIZE +'; line-height:' + Hosts.EMAIL_TEXT_LINE_HEIGHT  +'; color:' + Hosts.EMAIL_TEXT_COLOR +'">' +
                                            '<div>'+
                                                '<span>'+ dados.current.info.name + language[lang].email_contact +'</span>'+
                                                '<br/>'+
                                                '<br/>'+
                                                '<span><b>'+ language[lang].name +': </b><label>' + dados.current.info.name + '</label></span>' +
                                                '<br/>'+
                                                '<span><b>Email: </b><label>' + dados.current.info.email + '</label></span>'+
                                                '<br/>'+
                                                '<span><b>'+ language[lang].subject +': </b><label>' + dados.current.info.subject + '</label></span>' +
                                                '<br/>'+
                                                '<br/>'+
                                                '<span><label>' + dados.current.info.message.replaceAll('\n', '<br/>') + '</label></span>' +
                                                '<br/>'+
                                            '</div>'+
                                        '</td>' +
                                    '</tr>' +
                                '</table>' +
                                '<table width="600" cellpadding="0" cellspacing="0" border="0" align="0" class="0" style="width: 600px; background-color:' + Hosts.FOOTER_EMAIL_COLOR +'; padding: 10px; border: 1px solid transparent; border-collapse:collapse;">' +
                                    '<tr style="border-collapse: collapse;">' +
                                        '<td align="left" style="padding-bottom: 10px; padding-top: 10px; border-collapse:collapse; font-family:poppins, helvetica, arial, sans-serif; font-size:' + Hosts.FOOTER_EMAIL_TEXT_SIZE +'; line-height:' + Hosts.FOOTER_EMAIL_TEX_LINE_HEIGHT  +';">' +
                                            '<div>'+
                                                '<div style="text-align: center; font-family:poppins, helvetica, arial, sans-serif; font-size:' + Hosts.FOOTER_EMAIL_TEXT_SIZE +'; line-height:' + Hosts.FOOTER_EMAIL_TEX_LINE_HEIGHT  +'; color:' + Hosts.FOOTER_EMAIL_TEXT_COLOR +';">'+
                                                    '<span style="text-transform: uppercase;">' + Hosts.webSiteTitle + '</span>'+
                                                    '<br/>'+
                                                    '<span>' + Hosts.webSiteAdress + '</span>'+
                                                    '<br/>'+
                                                    '<a alt="' + Hosts.webSiteTitle + '" title="' + Hosts.webSiteTitle + '" href="' + Hosts.EMAIL_WEB_SITE_URL + '" target="_blank" style="font-family:poppins, helvetica, arial, sans-serif; font-size:' + Hosts.FOOTER_EMAIL_TEXT_SIZE +'; line-height:' + Hosts.FOOTER_EMAIL_TEX_LINE_HEIGHT  +';"> '+
                                                        '<span style="color:' + Hosts.FOOTER_EMAIL_TEXT_COLOR +';font-family:poppins, helvetica, arial, sans-serif; font-size:' + Hosts.FOOTER_EMAIL_TEXT_SIZE +'; line-height:' + Hosts.FOOTER_EMAIL_TEX_LINE_HEIGHT  +'; text-decoration: underline" >'+ Hosts.EMAIL_WEB_SITE_URL +'</span>' +
                                                    '</a>'+
                                                '</div>'+
                                            '</div>' +
                                        '</td>' +
                                    '</tr>' +
                                '</table>' +
                            '</td>' +
                        '</tr>' +
                    '</table>' +
                '</div>' +
            '<body><html>',
        // attachments -> opcional 
        attachments: [
            {   // encoded string em Base 64
                filename: files_doc_name,
                //filename: 'logo.svg',
                path: file_url,
                //path: file_base64,
                //path: 'https://temp.assec.pt/repo/turistrela/website/static/assets/logo/logo.svg',
            },
        ] 

    }; 

    /*
    let mailOptions = {
        from: Hosts.contactFrom,
        // vários emails separados por virgulas
        to: dados.current.info.email,
        //to: 'suporte@assec.pt',

        //cc-> opcional
        cc: Hosts.contactFrom,
        subject: Hosts.webSiteTitle + ' - ' + language[lang].contact_form,
        //text: 'Teste de EMAIL ', -> opcional
        html:
            '<div style="width:100% !important;margin-top:0;margin-bottom:0;margin-right:0;margin-left:0;padding-top:0;padding-bottom:0;padding-right:0;padding-left:0;color:#425968;background-color:#F7F7F7;background-image:none;background-repeat:repeat;background-position:top left;background-attachment:scroll;font-family:Arial,Helvetica,sans-serif;font-size:14px;line-height:16px;">' +
                '<table border="0" align="center" cellpadding="0" cellspacing="0" style="margin-top:0;margin-bottom:0;padding-top:0;padding-bottom:0;padding-right:0;padding-left:0;border-collapse:collapse;">' +
                    '<tr style="border-collapse: collapse;">' +
                        '<td style="border-collapse: collapse;">' +
                            '<table width="600" cellpadding="0" cellspacing="0" border="0" align="0" class="0" style="width: 600px; height: 90px; background-color:#ffffff;border:1px solid transparent;border-collapse:collapse;">' +
                                '<tr style="border-collapse: collapse;">' +
                                    '<td align="left" class="logo" style="padding:0; border-collapse:collapse;">' +
                                        '<a alt="' + Hosts.webSiteTitle + '" title="' + Hosts.webSiteTitle + '" href="' + Hosts.EMAIL_WEB_SITE_URL + '" target="_blank">' +
                                            '<img style="width: 600px; height: 90px;" width="600" height="90" src="' + repo_banner_link("banner-email.jpg") + '"/>' +
                                        '</a>'+
                                    '</td>' +
                                '</tr>' +
                            '</table>' +
                            '<table width="540" cellpadding="0" cellspacing="0" border="0" align="0" class="0" style="width: 540px; height: 90px; padding: 15px 30px; background-color:#ffffff;border:1px solid transparent;border-collapse:collapse;">' +
                                '<tr style="border-collapse: collapse;">' +
                                    '<td align="left" class style="padding:0 0 10px; border-collapse:collapse;">' +
                                        '<div style="width:540px; margin: 0 auto; padding-left: 30px; padding-right: 30px;">'+
                                            '<h4 style="text-transform: uppercase;">'+ language[lang].contact_form +':</h4>'+
                                            '<span><b>'+ language[lang].name +': </b><label>' + dados.current.info.name + '</label></span>' +
                                            '<br/>'+
                                            '<span><b>Email: </b><label>' + dados.current.info.email + '</label></span>'+
                                            '<br/>'+
                                            '<span><b>'+ language[lang].subject +': </b><label>' + dados.current.info.subject + '</label></span>' +
                                            '<br/>'+
                                            '<span><b>'+ language[lang].message +': </b><label>' + dados.current.info.message + '</label></span>' +
                                            '<br/>'+
                                        '</div>'+
                                    '</td>' +
                                '</tr>' +
                            '</table>' +
                            '<table width="600" cellpadding="0" cellspacing="0" border="0" align="0" class="0" style="width: 600px; height: 90px; background-color:' + Hosts.FOOTER_EMAIL_COLOR +'; padding: 10px; border:1px solid transparent;border-collapse:collapse;">' +
                                '<tr style="border-collapse: collapse;">' +
                                    '<td align="left" style="padding:10px 0px; border-collapse:collapse;">' +
                                        '<div style="margin: 0 auto; width:600px;">'+
                                            '<div style="text-align: center; font-size: small; color: #FFFFFF;">'+
                                                '<span style="text-transform: uppercase;">' + Hosts.webSiteTitle + '</span>'+
                                                '<br/>'+
                                                '<span>' + Hosts.webSiteAdress + '</span>'+
                                                '<br/>'+
                                                '<a alt="' + Hosts.webSiteTitle + '" title="' + Hosts.webSiteTitle + '" href="' + Hosts.EMAIL_WEB_SITE_URL + '" target="_blank" style="text-decoration:none;"> '+
                                                    '<span style="color: #FFFFFF;" >'+ Hosts.EMAIL_WEB_SITE_URL +'</span>' +
                                                '</a>'+
                                            '</div>'+
                                        '</div>' +
                                    '</td>' +
                                '</tr>' +
                            '</table>' +
                        '</td>' +
                    '</tr>' +
                '</table>' +
            '</div>',
        // attachments -> opcional 
        attachments: [
            {   // encoded string em Base 64
                filename: files_doc_name,
                //filename: 'logo.svg',
                path: file_url,
                //path: file_base64,
                //path: 'https://temp.assec.pt/repo/turistrela/website/static/assets/logo/logo.svg',
            },
        ] 

    }; 
    */

    const handleSubmit = async e => {
        e.preventDefault();

        //alert('SUBMIT');
        //return;

        set_file_url('1')


        await uploadFile(upload_file)
            .then(async (res) => {

                set_file_url(res)
                mailOptions.attachments[0].path = res

                reqPOST(`${Hosts.SIMCore_API_MAIL}`, {data: mailOptions})
                    .then(() => { 
                        //console.log('Notificações enviadas com sucesso') 
                        set_dados(dados.current = JSON.parse(JSON.stringify(data_interface)))
                        document.getElementById('agree').checked = false
                        setAgree(false)
                        //setRefresh(refresh + 1)
                        set_file_url('')
                        Swal.fire({
                            title: language[lang].contact_form,
                            text: language[lang].sent_successfully,
                            icon: 'success',
                            //showCancelButton: true,
                            confirmButtonColor: '#08396a',
                            //confirmButtonText: 'Tentar novamente',
                        })
                
                    })
                    .catch((error) => console.log(error)/*alert('Problema ao enviar notificações!')*/)
            
            })

    }

    const onChangeHandlerFile = async (e) => {
        const { files } = e.target;

        // set_is_uploading('')
        set_upload_file(files[0])

        // setTimeout(function(){
            // set_file_url(res)
            set_files_doc_name(files[0].name)
            set_files_doc(e)
            // set_is_uploading(' d-none')
        // }, 5000);


        /*
        await uploadFile(files[0])
            .then(async (res) => {

                setTimeout(function(){
                    set_file_url(res)
                    set_files_doc_name(files[0].name)
                    set_files_doc(e)
                    set_is_uploading(' d-none')
                }, 5000);

            })
        */
        /*
        convertBase64(files[0])
            .then((res) => {
                set_file_base64(res)
                set_files_doc_name(files[0].name)
                set_files_doc(e)
            })
        */
    }

    const uploadFile = async (file) => {
		const formData = new FormData()
		formData.append('files', file)
		formData.append('id', '')
		formData.append('file_name', file.name)
		formData.append('type', 'tmp')
		formData.append('companycode', dados.current.info.companycode)
		formData.append('folder', Hosts.APP_REPO_FOLDER + 'upload/tmp/');

		return await reqFILE(`${Hosts.SIMCore_API}/multimedia/file`, formData)
			.then(async (res) => {


                set_file_url(res.data.path + '/' + res.data.name)
                set_files_doc_name(file.name)
                set_files_doc(file)
                // set_is_uploading(' d-none')

				return res.data.path + '/' + res.data.name

			})
			.catch(erro => console.log('Erro POST File: ' + erro))
	}

    /*
    const convertBase64 = async (file) => {
		return new Promise((resolve, reject) => {
			const fileReader = new FileReader();
			fileReader.readAsDataURL(file);

			fileReader.onload = () => {
				resolve(fileReader.result);
			};

			fileReader.onerror = (error) => {
				reject(error);
			};
		});
	};
    */

    return (
        <section id="app_form" className="career-form"> 
            <div className={file_url === '' ? 'd-none' : ''}>
                <Loader />
            </div>

            <div className="container mt-4">
                <form onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-12">
                            <div className="row">
                                <div className="col-12">
                                    <p>
                                        {language[lang].read_terms_conditions}
                                        {/*Leia os nossos <a href={Hosts.WEB_SITE_URI + "terms_conditions"} target="_blank" rel="noreferrer">termos e condições</a> antes de submeter o formulário. Ao submeter concorda com os termos do nosso serviço.*/}
                                    </p>
                                </div>
                                
                                <div className="col-12 col-sm-6">
                                    <label>{language[lang].name}*</label>
                                    <input type="text" name="name" value={dados.current.info.name} className="form-control" placeholder="" onChange={handle_change_info} required />
                                </div>

                                <div className="col-12 col-sm-6">
                                    <label>{language[lang].email}*</label>
                                    <input 
                                        type="email" 
                                        name="email" 
                                        value={dados.current.info.email} 
                                        className="form-control" 
                                        placeholder="" 
                                        onChange={handle_change_info} 
                                        required
                                    /> 
                                </div>

                                <div className="col-12 mt-2">
                                    <label>{language[lang].subject}*</label>
                                    <input 
                                        type="subject" 
                                        name="subject" 
                                        value={dados.current.info.subject} 
                                        className="form-control" 
                                        placeholder="" 
                                        onChange={handle_change_info} 
                                        required
                                    />
                                </div>

                                <div className="col-12 col-sm-6 mt-3">
                                    <label className="">{language[lang].cv}*</label>
                                    <div style={{position: 'relative'}}>
                                        <span className="custom-file-label overflow-input" style={{left: '0', top: '3px'}} htmlFor={"input-files-pdf-0"}>{files_doc_name !== undefined ? files_doc_name : ''}</span>
                                        <input
                                            id={"input-files-pdf-0"}
                                            name="docs"
                                            className="custom-file-input"
                                            //defaultValue={files_doc_name[0] !== undefined ? files_doc_name[0].name : ''}
                                            type="file"
                                            accept="application/pdf"
                                            onChange={(e) => onChangeHandlerFile(e)}
                                            //disabled={(dados.current.info.vat === '' || dados.current.info.course.id === '')}
                                            //multiple
                                        />
                                        <div className={"spinner-border" + is_uploading} style={{position: 'absolute', top: '5px'}} role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 mt-2 form-group">
                                    <label>{language[lang].message}*</label>
                                    <textarea
                                        style={{ height: "180px", width: "100%" }}
                                        id="message"
                                        name="message"
                                        maxLength="1200"
                                        value={dados.current.info.message}
                                        onChange={handle_change_info_textarea}
                                        required
                                    />
                                    <p className="text-right my-2 small">{language[lang].maximum_numbers_of_caracters}&#58;&nbsp;{counterText.length}/1200</p>
                                    {/* <TextareaCounter countLimit={1200} style={{height: "100px", width: "100%"}} id="message" name="message" value={dados.current.info.message} onChange={handle_change_info} required/>
                                    <input type="text" name="message" value={dados.current.info.message} className="form-control" placeholder="" onChange={handle_change_info} /> */}
                                </div>

                                <div className="col-12">
                                    <input type="checkbox" id="agree" onChange={checkboxHandler} defaultChecked={checkboxHandler_reload(agree)}/>
                                    <label className="pl-2" htmlFor="agree">{language[lang].agree_terms_conditions}</label> <a href={Hosts.WEB_SITE_URI + "terms-conditions"} target="_blank" rel="noreferrer">{language[lang].terms_conditions_link}</a>
                                    <label className="pl-2 small" htmlFor="agree">{language[lang].terms_conditions_required}</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">

                    </div>

                    <div className="text-right">
                        {/*{console.log('AGREE2', agree)}
                        {console.log('MAIL: ', JSON.stringify(mailOptions))}*/}
                        <Button disabled={!agree} className="mt-5 mx-auto" color="tertiary">{language[lang].send}</Button>     
                    </div>

                </form>
            </div>
                
        </section>
    );


};

export default CareerForm;