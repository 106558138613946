import React, { useState, useEffect/*, useRef*/ } from 'react';
import { useLocation } from 'react-router-dom';

import './Navbar.css';

import { language } from '../WEBContent/Language';

import { reqGET, set_language, repo_logo_link, repo_site_documents_link, content_language } from '../../../Utils';

//import { faUser } from "@fortawesome/free-solid-svg-icons";
// import { faLongArrowAltLeft, faAngleDown } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import * as Hosts from "../../../Hosts";
// import { set } from 'date-fns';

//import jwt_decode from "jwt-decode";

/* import { Link, animateScroll as scroll } from "react-scroll"; */
//import { Link } from "react-scroll";

/*const getTokenInfo = () => {

    const tokenString = localStorage.getItem('token');
    //console.log(tokenString)
    if (tokenString !== null)
        return jwt_decode(JSON.parse(tokenString).token);
    else
        return null
};*/

const Navbar = (props) => {

    const data_interface = {
        id: '',
        info: {},
        content: {
            childs: []
        }
    }

    const [navbar, set_navbar] = useState(data_interface);
    /*const [navbar_active, set_navbar_active] = useState(false); */

    const [lang, set_lang] = useState(localStorage.getItem('lang'));
    const [lang_id, set_lang_id] = useState('1');

    //const [login_name,] = useState(getTokenInfo() === null ? '' : getTokenInfo().name);


    //const page_location = useLocation().pathname.split('/')[2];


	const [/*refresh*/, setRefresh] = useState(0);

	const [isloading, setLoading] = useState(true);

    const location = useLocation().pathname;
    const page_location = useLocation().pathname.split('/').pop();

    const change_lang = (l) => {
        
        set_language(l)
        set_lang(l)

        // window.location.assign(location)
        window.location.reload()

        //console.log('language', localStorage.getItem('lang'))
        //console.log('localstorage', localStorage)
    }


    /*function do_logout() {
        localStorage.clear();
        window.location.reload()
    }*/

    
    useEffect(() => {

        if (localStorage.getItem('lang') === undefined || localStorage.getItem('lang') === null || localStorage.getItem('lang') === '') {
            set_lang('pt')
            set_language('pt')
            set_lang_id('1')
        }
        else {
            set_lang(localStorage.getItem('lang'))
            content_language.forEach(el => {
                if (el.code === localStorage.getItem('lang')) {
                set_lang_id(el.id)
                }
            })
        }

        //set_lang(localStorage.getItem('lang'))

        //set_navbar({ id: '', info: '', content: { childs: [] } })

        let getInfo = async () => {

            /*reqGET(`${Hosts.SIMCore_API}/web/content/type/${Hosts.companycode}/banner`)
                .then(res => { 
                    setBanner(res.data)
                    //console.log(res.data)
                    setLoading(false)
                    //calculo_margem()
                })
                .catch(erro => console.log('Erro', erro))*/
    
            await reqGET(`${Hosts.SIMCore_API}/web/structure/${Hosts.companycode}/1`)
                .then(res => {
                    if(res.data.length > 0) {
                        res.data.forEach((v) => {
                            if (v.info.principal === true) {
                                //set_dados(dados.current = v);
    
                                if(lang !== 'pt') {
                                    let x = 0
                                    content_language.forEach((cl) => {
                                        if(cl.code === lang) {
                                            v.content.childs.forEach(async (el) => {
                                                if(el.id_content !== '') {
                                                    if(el.children !== undefined) {
                                                        x = x - el.children.length
                                                        el.children.forEach(async (el_c) => {
                                                            await reqGET(`${Hosts.SIMCore_API}/web/content/${Hosts.companycode}/${el_c.id_content}`)
                                                                .then(res_3 => {
                                                                    if(res_3.data.length > 0) {
                                                                        if(res_3.data[0].info.language.length > 0) {
                                                                            if(res_3.data[0].info.language[cl.id] !== undefined && res_3.data[0].info.language[cl.id] !== null) {
                                                                                if(res_3.data[0].info.language[cl.id]['name'] !== '') {
                                                                                    el_c.title = res_3.data[0].info.language[cl.id]['name'];
                    
                                                                                    x++
                                                                                    if(v.content.childs.length === x) {
                                                                                        setRefresh(refresh => refresh + 1)
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                        else {
                                                                            x++
                                                                            if(v.content.childs.length === x) {
                                                                                setRefresh(refresh => refresh + 1)
                                                                            }
                                                                        } 
                                                                    }
                                                                    else {
                                                                        x++
                                                                        if(v.content.childs.length === x) {
                                                                            setRefresh(refresh => refresh + 1)
                                                                        }
                                                                } 
                                                            })
                                                        })
                                                    }

                                                    await reqGET(`${Hosts.SIMCore_API}/web/content/${Hosts.companycode}/${el.id_content}`)
                                                        .then(res_2 => {
                                                            if(res_2.data.length > 0) {
                                                                if(res_2.data[0].info.language.length > 0) {
                                                                    if(res_2.data[0].info.language[cl.id] !== undefined && res_2.data[0].info.language[cl.id] !== null) {
                                                                        if(res_2.data[0].info.language[cl.id]['name'] !== '') {
                                                                            el.title = res_2.data[0].info.language[cl.id]['name'];
                                                                            
                                                                            x++
                                                                            if(v.content.childs.length === x) {
                                                                                setRefresh(refresh => refresh + 1)
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                                else {
                                                                    x++
                                                                    if(v.content.childs.length === x) {
                                                                        setRefresh(refresh => refresh + 1)
                                                                    }
                                                                }  
                                                            } 
                                                            else {
                                                                x++
                                                                if(v.content.childs.length === x) {
                                                                    setRefresh(refresh => refresh + 1)
                                                                }
                                                            } 
                                                        })
    
                                                    await reqGET(`${Hosts.SIMCore_API}/web/content/${Hosts.companycode}/${el.id_content}`)
                                                        .then(res_2 => {
                                                            if(res_2.data.length > 0) {
                                                                if(res_2.data[0].info.language.length > 0) {
                                                                    if(res_2.data[0].info.language[cl.id] !== undefined && res_2.data[0].info.language[cl.id] !== null) {
                                                                        if(res_2.data[0].info.language[cl.id]['name'] !== '') {
                                                                            el.title = res_2.data[0].info.language[cl.id]['name'];
              
                                                                            x++
                                                                            if(v.content.childs.length === x) {
                                                                              setRefresh(refresh => refresh + 1)
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                                else {
                                                                    x++
                                                                    if(v.content.childs.length === x) {
                                                                        setRefresh(refresh => refresh + 1)
                                                                    }
                                                                } 
                                                            }
                                                            else {
                                                                x++
                                                                if(v.content.childs.length === x) {
                                                                    setRefresh(refresh => refresh + 1)
                                                                }
                                                            } 
                                                        })
        
                                                }
                                                else {
                                                    if(el['language'] !== undefined && el['language'] !== null) {
                                                        if(el.language[cl.id] !== undefined && el.language[cl.id]['title'] !== undefined && el.language[cl.id]['title'] !== '') { 
                                                            el.title = el.language[cl.id]['title']
              
                                                            x++
                                                            if(v.content.childs.length === x) {
                                                              setRefresh(refresh => refresh + 1)
                                                            }
                                                        }
                                                    }
                                                }
                                            })
                                        }
                                    })
                                }
    
                                set_navbar(v);
                                setLoading(false)
                            }
                        })
                    }
                    else {
                        return []
                    }
    
                })
                .catch(erro => console.log('ERROR', erro))
        }
    
        getInfo()

        // if (localStorage.getItem('lang') === undefined || localStorage.getItem('lang') === null || localStorage.getItem('lang') === '') {
        //     change_lang('pt')
        // }
        // else {
        //     set_lang(localStorage.getItem('lang'))
        // }

        //console.log('language', localStorage.getItem('lang'))
        //console.log('localstorage', localStorage)


    }, [lang])

    return isloading ? 
        null    
    :
        (
        <>
            <div className="d-none d-print-block">
                <img src={repo_logo_link("logo.svg")} alt={""} className="svg-logo img-fluid" />
            </div>
            
            <div className="sticky-top">
                <section id="navbar">

                    <nav className="navbar navbar-expand-lg navbar-light py-0 px-0 nav-effect">
                        <div className="col-12">
                            <div className="row">
                                <div className="top w-100">
                                    <div className="col-12 top-bar">
                                        <div className="menu_superior">
                                            <div className="top-phone d-none"><i className="bi bi-telephone"></i><a href="tel:(+351)275334933" title="(+351) 275 334 933">(+351) 275 334 933</a> <small>{language[lang].call_landline}</small></div>
                                            <div className='d-none d-lg-block'><a href={repo_site_documents_link('CENTRO-04-38D7-FEDER-001180.pdf')} style={{fontSize: '10px'}} target="_blank" rel="noreferrer">{language[lang].projeto_cofinanciado}</a></div>
                                            <div className="small">
                                                {/*
                                                <a href="#" className={(lang === 'pt' ? 'current_top' : '')} onClick={() => change_lang('pt')}>PT</a>
                                                <a href="#" className={(lang === 'en' ? 'current_top' : '')} onClick={() => change_lang('en')}>EN</a>
                                                <a href="#" className={(lang === 'es' ? 'current_top' : '')} onClick={() => change_lang('es')}>ES</a>
                                                <a href="#" className={(lang === 'fr' ? 'current_top' : '')} onClick={() => change_lang('fr')}>FR</a>
                                                */}
                                                <button className={"pr-2 button_no_border " + (lang === 'pt' ? ' current_top current_lang' : '')} onClick={() => change_lang('pt')}>PT</button>
                                                <button className={"pr-2 button_no_border " + (lang === 'en' ? ' current_top current_lang' : '')} onClick={() => change_lang('en')}>EN</button>
                                                <button className={"pr-2 button_no_border " + (lang === 'es' ? ' current_top current_lang' : '')} onClick={() => change_lang('es')}>ES</button>
                                                <button className={"p-2 button_no_border " + (lang === 'fr' ? ' current_top current_lang' : '')} onClick={() => change_lang('fr')}>FR</button>

                                                {/*<a className={(login_name !== '' ? 'd-none ' : '') + (page_location === 'form_author' ? 'current_top' : '')} href={Hosts.WEB_SITE_URI + 'form_author'}>{language[lang].regist}</a>
                                                <span className={login_name !== '' ? 'd-none' : 'px-2'}>&#124;</span>

                                                <a href={Hosts.WEB_BACKOFFICE_URI} target="_blank" rel="noreferrer">
                                                    <FontAwesomeIcon className={"font-weight-small " + (login_name !== '' ? '' : 'd-none')} icon={faUser} fixedWidth />
                                                    {login_name !== '' ? login_name : language[lang].login}
                                                </a>
                                                <span className={login_name !== '' ? 'px-2' : 'd-none'}>&#124;</span>
                                                <button className={"button_no_border p-0"} onClick={() => do_logout()}>
                                                    {login_name !== '' ? 'Logout' : ''}
                                                </button>*/}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 d-none">
                                    <div className="row text-center border-bottom">
                                        <a className="navbar-brand pt-0 pb-0 mr-auto ml-auto " href={Hosts.WEB_SITE_URI} alt="">
                                            <img id="big-logo" src={repo_logo_link("logo.svg")} alt={Hosts.webSiteTitle} title={Hosts.webSiteTitle}
                                            className="svg-logo img-fluid" />
                                        </a>
                                    </div>
                                </div>


                                <div className="col-12 d-lg-none bg-color-white py-lg-3" style={{display: 'inline-flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                    <a className="navbar-brand" href={Hosts.WEB_SITE_URI}>
                                        <img id="small-logo" src={repo_logo_link("logo.svg")} alt={Hosts.webSiteTitle} title={Hosts.webSiteTitle} className="svg-logo-small img-fluid" />
                                    </a>
                                    <button className="navbar-toggler float-right" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                                        <span className="navbar-toggler-icon"></span>
                                    </button>
                                </div>

                                <div className="col-12 collapse navbar-collapse py-md-2 px-lg-3" id="navbarNav">
                                    <a className="navbar-brand py-0 d-none d-lg-block" href={Hosts.WEB_SITE_URI} alt="">
                                        <img id="big-logo" src={repo_logo_link("logo.svg")} alt={Hosts.webSiteTitle} title={language[lang].not_found} className="svg-logo img-fluid" />
                                    </a>
                                    <ul className="navbar-nav ml-auto my-2">
                                        {/*console.log('RES DATA MENU', navbar)*/}

                                        {
                                            props.mostrar_botao_voltar ?

                                                <li className="nav-item" /* key={key} */ >
                                                    <a href={Hosts.WEB_SITE_URI} className={"nav-link "}>
                                                        <i className="bi bi-chevron-down ml-1" />
                                                        {language[lang].back}
                                                    </a>
                                                </li>

                                            :

                                            navbar.content.childs.map((v, key) => {
                                                
                                                /* if (v.id === 17) { */
                                                    // console.log('v', v)
                                                    let link = ''
                                                    v.id_content !== '' ?
                                                        link = (v.slug !== undefined && v.slug !== '' ? Hosts.WEB_SITE_URI + '' + v.slug : Hosts.WEB_SITE_URI + 'pag/' + v.id_content)
                                                    :
                                                        link = (v.slug !== undefined && v.slug !== '' ? Hosts.WEB_SITE_URI + '' + v.slug : Hosts.WEB_SITE_URI + v.link)

                                                        let current_top_check = (v.slug !== undefined && v.slug !== '' ? v.slug : v.id_content);
                                                    if (v.children !== undefined && v.children !== '' && v.children.length > 0) {
                                                        return (
                                                            <li className="nav-item dropdown" key={key}>
                                                                <a href="true" className="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown" aria-expanded="false">
                                                                    {v.title}
                                                                    <i className="bi bi-chevron-down ml-1" style={{color: 'var(--orange-dark)'}} />
                                                                </a>
                                                                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                                                    {
                                                                        v.children.map((v2, key2) => {

                                                                            // console.log('v2', v2)

                                                                            let link_dropdown_item = ''
                                                                            v2.id_content !== '' ?
                                                                                link_dropdown_item = (v2.slug !== undefined && v2.slug !== '' ? Hosts.WEB_SITE_URI + '' + v2.slug : Hosts.WEB_SITE_URI + 'pag/' + v2.id_content)
                                                                            :
                                                                                link_dropdown_item = (v2.slug !== undefined && v2.slug !== '' ? Hosts.WEB_SITE_URI + '' + v2.slug : Hosts.WEB_SITE_URI + v2.link)
                                                                            
                                                                            let current_top_dropdown = (v2.slug !== undefined && v2.slug !== '' ? v2.slug : v2.id_content);
                                                                            if(current_top_dropdown === '') {
                                                                                if(location === '/' + v2.link) {
                                                                                    current_top_dropdown = v2.link
                                                                                }
                                                                            }

                                                                            return (
                                                                                v2.link.substr(0, 4) !== 'http' ?
                                                                                    <a key={key2} href={link_dropdown_item} className={"dropdown-item " + ((page_location !== '' && page_location === current_top_dropdown) || (page_location !== '' && location === '/' + current_top_dropdown) ? 'current_top' : '')}  >
                                                                                        {lang === 'pt' || v2.language === undefined ? v2.title : (v2.language[lang_id] === undefined || v2.language[lang_id] === null || v2.language[lang_id].title === undefined || v2.language[lang_id].title === '' ? v2.title : v2.language[lang_id].title)}
                                                                                    </a>
                                                                                :
                                                                                    <a key={key2} href={link_dropdown_item} className={"dropdown-item " + ((page_location !== '' && page_location === current_top_dropdown) || (page_location !== '' && location === '/' + current_top_dropdown) ? 'current_top' : '')} target="_blank" rel="noreferrer" >
                                                                                        {lang === 'pt' || v2.language === undefined ? v2.title : (v2.language[lang_id] === undefined || v2.language[lang_id] === null || v2.language[lang_id].title === undefined || v2.language[lang_id].title === '' ? v2.title : v2.language[lang_id].title)}
                                                                                    </a>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            </li>
                                                        )
                                                    }
                                                    else {
                                                        return (
                                                            v.link.substr(0, 4) !== 'http' ?
                                                                <li className="nav-item" key={key} >
                                                                    <a href={link} className={"nav-link " + (page_location === current_top_check ? 'current_top' : '')} >
                                                                        {v.title}
                                                                    </a>
                                                                </li>
                                                            :
                                                                <li className="nav-item" key={key} >
                                                                    <a href={link} className={"nav-link " + (page_location === current_top_check ? 'current_top' : '')} target="_blank" rel="noreferrer" >
                                                                        {v.title}
                                                                    </a>
                                                                </li>
                                                            
                                                        )

                                                    }
                                                /* } */
                                                /* return (
                                                    <li className="nav-item" key={key}>
                                                        <Link activeClassName="active" to={v.link} spy={true} smooth={true} offset={-150} duration={600}
                                                            className={"nav-link " + (page_location === 'scientia_magazine' ? 'current_top' : '')}>
                                                            {v.title}
                                                        </Link>
                                                    </li>
                                                ) */
                                            })
                                        }

                                        <li className="nav-item d-lg-none" >
                                            <hr />
                                            <a href={repo_site_documents_link('CENTRO-04-38D7-FEDER-001180.pdf')} className={"nav-link small"} style={{fontSize: '10px'}} target="_blank" rel="noreferrer">
                                                {language[lang].projeto_cofinanciado}
                                            </a>
                                        </li>
                                    </ul>
                                    <div className="overlay"></div>
                                </div>


                            </div>
                        </div>
                    </nav>
                </section >
            </div >
        </>
        )
};
export default Navbar;