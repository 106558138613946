import React, { useState, useEffect, useRef } from 'react';

import ReactHtmlParser/*, { processNodes, convertNodeToElement, htmlparser2 }*/ from 'react-html-parser';

import Navbar from '../Navbar/Navbar';
import Loader from '../../../loader';
import Breadcrumb from '../Breadcrumb/Breadcrumb';
import Footer from "../Footer/Footer"; 

import AssecImage from "../ASSEC_Image/Image"

import * as Hosts from '../../../Hosts';
import {
    set_language,
    reqGET,
    content_language

} from '../../../Utils';

import CareerForm from "../Forms/CareerForm"; 

const ContactFormCarrers = (props) => {

    const [slug, ] = useState(props.info.state.slug);

    const [lang, set_lang] = useState('pt');
    const [lang_id, set_lang_id] = useState('1');
    const [id_parent, set_id_parent] = useState('');   

    const [, set_dados] = useState(null);
	const content = useRef(null);

    const [isloading, setLoading] = useState(true);

    useEffect(() => { 

        let getInfo = async (id) => {

            return await reqGET(`${Hosts.SIMCore_API}/web/content/${Hosts.companycode}/${id}`)
                .then(async res => { 
                    //setData(res.data)
    
                    set_dados(content.current = res.data[0])
                    return  true
                })
                .catch(erro => alert('Erro', erro))
    
        }
    
        let get_item_parent_by_link = async (array) => {
    
            await array.forEach((el) => {
                if(el.link.includes(slug)) {
                    set_id_parent(el.parent)
                }
                else {
                    if(el.children !== undefined)
                        get_item_parent_by_link(el.children)
                }
            })
    
        }

        let getMenu = async () => {
            //setLoading(true)
        
            return await reqGET(`${Hosts.SIMCore_API}/web/structure/${Hosts.companycode}/1`)
                .then((res) => {

                    get_item_parent_by_link(res.data[0].content.childs)
                        .then((res_2) => {
                            
                        })
                        .catch(error => console.log('Erro 2', error))

                })
                .catch(error => console.log('Erro', error))
        }
        getMenu()
            .then(() => {
                //setLoading(false)
            })

        if(id_parent !== '') {
            getInfo(id_parent)
                .then((res) => {
                    if(res === true) {
                        setLoading(false)
                    }
                })
        }
        else {
            //setLoading(false)
        }

        //getInfo()
        if(localStorage.getItem('lang') === undefined  || localStorage.getItem('lang') === null || localStorage.getItem('lang') === '') {
            set_lang('pt')
            set_language('pt')
            set_lang_id('1')
        }
        else {
            set_lang(localStorage.getItem('lang'))
            content_language.forEach(el => {
                if(el.code === localStorage.getItem('lang')) {
                    set_lang_id(el.id)
                }
            })
        }

    }, [slug, id_parent]); 

    return (isloading ?

		    <Loader />

		:
            <div>
                <Navbar />
                <Breadcrumb />
                <section>

                    <div className="container">
                        <div className='row'>
                            <div className='col-12'>

                                    {
                                    content.current.content !== undefined ?
                                        content.current.content.map((el, k) => {  
                                            return (
                                                <section key={k} className="content-body">
                                                    <div className="container">
                                                        <div className="row">
                                                            <div className="col-12">
                                                                {
                                                                    lang !== 'pt' && el['language'] !== undefined && el['language'][lang_id] !== undefined && el['language'][lang_id] !== null && el['language'][lang_id]['media'] !== null && el['language'][lang_id]['media']['image'] !== undefined && el.language[lang_id]['media']['image'].length > 0 ?

                                                                        <div className="div-image w-100"> 
                                                                            <AssecImage 
                                                                                linkImage={el['language'][lang_id]['media']['image'][0]['link']}
                                                                                width="1080"
                                                                                height="540"
                                                                                fit='crop'
                                                                                type='img'
                                                                                sClass="img-fluid" 
                                                                                alt={el['language'][lang_id]['media']['image'][0]['name']}
                                                                            />
                                                                            {/*<img src={el['language'][lang_id]['media']['image'][0]['link']} className="img-fluid" alt={el['language'][lang_id]['media']['image'][0]['name']} />*/}
                                                                        </div>

                                                                    :

                                                                        el['media'] !== undefined && el['media']['image'] !== undefined && el['media']['image'].length > 0 ? (
                                                                            <div className="div-image w-100"> 
                                                                                <AssecImage 
                                                                                    linkImage={el['media']['image'][0]['link']}
                                                                                    width="1080"
                                                                                    height="540"
                                                                                    fit='crop'
                                                                                    type='img'
                                                                                    sClass="img-fluid" 
                                                                                    alt={el['media']['image'][0]['name']}
                                                                                />
                                                                                {/*<img src={el['media']['image'][0]['link']} className="img-fluid" alt={el['media']['image'][0]['name']} />*/}
                                                                            </div>

                                                                        ) : null 

                                                                }

                                                                {
                                                                    <div className="div-content my-3">
                                                                        <h1 className={el.title !== '' ? "titulo-primario mt-3 mb-0" : "d-none"}>
                                                                            {lang === 'pt' || el.language === undefined ? el.title : (el.language[lang_id] === undefined || el.language[lang_id] === null || el.language[lang_id].title === undefined || el.language[lang_id].title === '' ? el.title : el.language[lang_id].title)}
                                                                        </h1>
                                                                        { 
                                                                            el.subtitle !== '' ?
                                                                                <h2 className={el.subtitle !== '' ? "titulo-secundario mb-3" : "d-none"}>
                                                                                    {lang === 'pt' || el.language === undefined ? el.subtitle : (el.language[lang_id] === undefined || el.language[lang_id] === null || el.language[lang_id].subtitle === undefined || el.language[lang_id].subtitle === '' ? el.subtitle : el.language[lang_id].subtitle)}
                                                                                </h2>
                                                                            :
                                                                                ''
                                                                        }
                                                                        { 
                                                                            el.text !== '' ?
                                                                                <div className="div-text">
                                                                                    {ReactHtmlParser(lang === 'pt' || el.language === undefined ? el.text : (el.language[lang_id] === undefined || el.language[lang_id] === null || el.language[lang_id].text === undefined || el.language[lang_id].text === '' ? el.text : el.language[lang_id].text))}
                                                                                </div>
                                                                            :
                                                                                ''
                                                                        }
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                            )
                                        })
                                    :
                                        null
                                    }

                            </div>
                            <div className='col-8 offset-2 my-5'>
                                <CareerForm />
                            </div>
                        </div>
                    </div>

                    </section>
                <Footer />
            </div>
    );


};

export default ContactFormCarrers;