const language = {
    'pt': 
        {
            abstract:           'Resumo', 
            accept_terms:       'Aceito os termos e condições.',
            access_data:        'Dados de acesso',
            adress:             'Morada',
            agree_terms_conditions: 'Concordo com os ', 
            all_news:           'Todas as notícias',
            all_insights:           'Mais Insights',
            article:            'Artigo',
            articles_submission:'Submissão de artigos',
            author_regist:      'Registo de autor', 
            authors:            'Autores',  
            app_form_title:     'Formulário de Inscrição Concurso de Ideias',
            app_swal_title:     'Formulário de Inscrição',
            app_form_title_01:  'Identificação do(s) promotor(es)',
            app_form_title_02:  'Setor de atividade',
            app_form_option_02_01:  'Turismo',
            app_form_option_02_02:  'Desporto',
            app_form_option_02_03:  'Serviços',
            app_form_option_02_04:  'Saúde',
            app_form_option_02_05:  'Comércio',
            app_form_option_02_06:  'Indústria',
            app_form_option_02_07:  'Ambiente',
            app_form_option_02_08:  'Energia',
            app_form_option_02_09:  'Agroalimentar',
            app_form_option_02_10:  'Agricultura',
            app_form_title_03:  'Fase de maturidade do negócio',
            app_form_option_03_01:  'Ideia',
            app_form_option_03_02:  'Pré-projeto',
            app_form_option_03_03:  'Projeto',
            app_form_option_03_04:  'Protótipo',
            app_form_title_04:  'Breve descrição da ideia/projeto',
            app_form_title_05:  'Problema(s) de que pretende resolver',
            app_form_title_06:  'Histórico do(s) promotor(es)',
            app_form_title_07:  'Qual a solução?',
            app_form_title_08:  'Quais os aspetos diferenciadores?',
            app_form_title_09:  'Caracterização do mercado',
            app_form_title_10:  'Descrição da estratégia de desenvolvimento da empresa',
            app_form_title_11:  'Descrição de fatores críticos de sucesso do negócio',
            app_form_title_12:  'Quais os parceiros?',
            app_form_title_13:  'Descrição do investimento',
            app_form_title_14:  'Previsões',
            back:               'Voltar',
            banner_submission:  'Submeta os seus artigos',
            banner_submission_2:'Conheça os próximos períodos de submissão e todas as informações necessárias.',
            banner_article_submission_text:  'Para submeter os seus artigos faça o seu login/registo e junte-se a nós!',
            bibliography:       'Bibliografia',
            biography:          'Biografia',
            btn_banner:         'Venha conhecer-nos',
            btn_mote:           'Conheça as ofertas',
            btn_serra:          'Descubra a Serra da Estrela',
            btn_news:           'Todas as notícias',
            btn_insight:        'Todos os artigos',
            btn_insights:       'Insights',
            btn_testimony:      'Deixe o seu testemunho',
            btn_subscribe:      'Subscrever',
            call_landline:      '(Chamada para a rede fixa nacional)',
            call_mobile:        '(Chamada para a rede móvel nacional)',
            cancel:             'Cancelar',
            cfp_status_closed:  'fechado',
            cfp_status_ending:  'a terminar',
            cfp_status_open:    'aberto',
            cfp_status_soon:    'brevemente',
            change_password_expiration_message:    'O código para alterar a sua palavra-passe expirou. Faça um novo pedido.',
            change_password_sucess_message:    'Palavra-passe atualizada com sucesso',
            close:              'Fechar',
            code:               'Código',
            confirmation_password_error_message:      'Palavra-passe de confirmação diferente da palavra-passe inserida',
            contest:            'Concurso',
            contacts:           'Contactos',  
            contact:            'Contacto',  
            contact_email:      'Email de contacto',
            contact_form:       'Formulário de contacto',  
            contact_recovery:   'Formulário de recuperação',
            careers_form:       'Formulário de Candidatura',
            content:            'Conteúdo',
            content_edit:       'Editar conteúdo',
            content_new:        'Novo conteúdo',
            content_list:       'Lista de conteúdos',
            cookies_message:    '<strong>ATENÇÃO:</strong> Este site utiliza cookies. Ao navegar no site estará a consentir a sua utilização.',
            cookie_more:        'Mais informações.',
            cookie_button_text : 'Aceitar todos',
            country:            'País',
            dates:              'Datas',
            date_creation:      'Criação',
            date_publication:   'Publicação',
            date_last_update:   'Última actualização',
            delete:             'Eliminar',
            delete_title:       'Eliminar Registo',
            delete_text:        'Tem a certeza que pretende continuar?',
            delete_confirm:     'Sim',
            delete_cancel:      'Não',
            delete_error:       'Problema ao apagar registo!',
            delete_success:     'Registo apagado com sucesso!',
            description:        'Descrição',
            developed:          'Desenvolvido por',
            discover:           'Descobrir',
            download:           'Descarregar',
            download_roteiro:   'Descarregue o roteiro',
            download_full_edition:           'Descarregar edição completa',
            designation:        'Designação da ideia/projeto',
            edit:               'Editar',
            edit_title:         'Editar Registo',
            edit_text:          'Tem a certeza que pretende continuar?',
            edit_confirm:       'Sim',
            edit_cancel:        'Não',
            edit_error:         'Problema ao actualizar registo!',
            edit_success:       'Registo actualizado com sucesso!',
            edition:            'Edição',
            email:              'Email',
            email_02:           'Email do responsável',
            email_contact:      ', obrigado pelo seu contacto!<br>Entraremos em contacto consigo com a maior brevidade possível.',
            email_careers:      'Informamos que recebemos a sua candidatura, iremos analisar e entraremos em contacto com a maior brevidade possível.',
            email_contest_02:   'Obrigada pela tua participação.',
            email_placeholder:  'Introduza o email',
            end:                'Fim',
            enter_country_residence:  'Introduza o país de residência',
            entrepreneur_information:   'Informação de Apoio ao Empreendedor',
            file_attach:        'Attach file',
            file_remove_all:    'Remove all',
            file_upload:        'Upload file',
            file_upload_all:    'Upload all',
            files:              'Ficheiro(s)',
            filter_placeholder: 'Procurar',
            footer_address:     'Rua Dr. António Plácido da Costa, 16 c/v, 6200-051 Covilhã<p></p>Tfl (+351) 275 310 651/2<p></p>Fax (+351) 275 310 659',

            footer_address_big:   'Turistrela - Turismo Serra da Estrela',
            footer_address_1:   'Turistrela',
            footer_address_11:   'Estrada Nacional 339<br>Apartado 332<br>6200-073 Covilhã',
            footer_address_12:   '(+351) 275 094 779',
            footer_address_13:   'comercial@turistrela.pt',
            footer_address_14:   'Nº de registo: 9/2003',
            footer_social:       'Fique ligado',
            footer_logos_01:     'Cofinanciado por:',
            footer_logos_02:     'Promovido por:',
            form_autor_register: 'Formulário de registo de autor',
            full_edition:       'Edição completa',
            gallery_view:       'Galeria de Fotos',
            go_platform:        'Ir para a plataforma',
            hello:              'Olá',
            id:                 'ID',
            image:              'Imagem',
            image_choose:       'Escolher imagem',
            info_banner_text:   'Todas as informações necessárias para começar',
            insert_title:       'Novo Registo',
            insert_text:        'Tem a certeza que pretende continuar?',
            insert_confirm:     'Sim',
            insert_cancel:      'Não',
            insert_error:       'Problema ao inserir registo!',
            insert_success:     'Registo inserido com sucesso!',
            learn_more:         'Saiba mais',
            location:           'Localização',
            login:              'Iniciar sessão',
            name:               'Nome',
            name_placeholder:   'Introduza o nome',
            news_turistrela:    'Notícias Turistrela',
            galleries_turistrela : 'Galerias Turistrela',
            insights_turistrela:    'Insights Turistrela',
            newsletter:         'Receba a nossa Newsletter',
            newsletter_banner_text: 'Receba informação sobre a Turistrela - Turismo Serra da Estrela.',
            not_found:          'Página inicial',
            not_found1:          'Oops! Nada foi encontrado',
            not_found2:          'A página que procura pode ter sido removida, ter o seu nome alterado ou estar temporariamente indisponível. ',
            not_found3:          'Voltar à página inicial. ',
            maximum_numbers_of_caracters:   'Número máximo de caracteres',
            message:            'Mensagem',
            message_placeholder:    'Introduza a mensagem',
            more:               'Mais informação:',
            multimedia:         'Multimédia',
            observations:       'Observações',
            opinion_banner_button:   'Deixar opinião',
            opinion_banner_title:   'Deixe-nos a sua opinião',
            opinion_banner_text:    'Os presentes testemunhos foram-nos dados de livre vontade, confirmando a disponibilidade e a confiança que damos aos nossos clientes e parceiros.',
            organization:       'Organização',
            open_file:          'Ver ficheiro',
            opinion_form:       'Formulário de opinião',
            page_config:        'Configuração da Página',
            page_parent:        'Parent',
            pages:              'Páginas',
            password:           'Palavra-passe',
            password_new:       'Nova palavra-passe',
            password_conf:      'Confirmação da palavra-passe',
            platforms:          'Plataformas',
            platforms_banner_text:  'Conheça as nossas plataformas que temos para si',
            principal:          'Principal',
            privacy_policy:     'Política de privacidade',
            profile_image:      'Imagem de perfil',
            project:            'Projeto',
            project_banner_text:    'Fique a conhecer todo o nosso projeto',
            read_article:       'Ler artigo',
            read_terms_conditions: 'Leia os nossos termos e condições antes de submeter o formulário.', 
            regist:             'Registo',
            regist_form_sucess_message: 'Registo efetuado com sucesso. Consulte os seus dados de acesso no email enviado.',
            register:           'Registe-se',
            registry_defined:   'Definida no registo',
            remove:             'Remover',
            reserved_rights:    'Todos os direitos reservados',
            reviewers:          'Revisores',           
            routes_turistrela:  'Roteiros Turistrela',
            partners_turistrela:    'Parceiros de Negócios',
            content_preview:    'Isto é uma Pré-visualização!', 
            careers_turistrela: 'Carreiras',
           
            //rules:              'Normas de submissão',
            save:               'Salvar', 
            see_new:            'Ler notícia', 
            see_more:           'Ver mais',
            see_partner:        'Conheça o nosso Parceiro',
            scientia_magazine:  'Revista Científica',
            search:             'Pesquisar', 
            search_by_article_name:             'Pesquisa por nome de artigo', 
            search_by_author_name:             'Pesquisa por nome de autor', 
            search_by_article_keyword:             'Pesquisa por keywords', 
            search_error_message:             'Preencha um dos campos de pesquisa.',
            search_error_noresults_message:             'Não foi encontrado nenhum resultado.',
            send:               'Enviar',   
            sent_successfully:  'Enviado com sucesso',
            seo_config:         'Configuração SEO',
            seo_description:    'Descrição SEO',
            seo_description_placeholder: 'Introduza a descrição SEO',
            seo_keywords:       'Keywords SEO',
            seo_keywords_small: '(separar por vírgula)',
            seo_keywords_placeholder: 'Introduza as keywords SEO',
            seo_title:          'Título SEO',
            seo_title_placeholder: 'Introduza o título SEO',
            slide_title:        'Juntos partilhamos conhecimento',
            start:              'Início',
            subject:            'Assunto',
            submission:         'Submissão',
            submission_rules:   'Normas de submissão',
            submission_rules_pdf:   'Descarregar instruções',
            submission_rules_pdf_view:   'Ver normas de submissão',
            submit:             'Submeter',
            submit_article:     'Submeter artigo',
            surname:            'Apelido',
            table_rows_per_page:'Registos por página:',
            table_separator:    'de',
            table_all:          'Todos',
            table_title:        'Título',
            table_parent:       'Parent',
            table_date:         'Data',
            table_state:        'Estado',
            table_actions:      'Acções',
            technical_team:     'Corpo técnico',
            terms_conditions:   'Termos e condições',          
            terms_conditions_link:   'termos e condições.',
            terms_conditions_required: 'Os campos assinalados com (*) são de preenchimento obrigatório. O endereço de email permite um contacto mais rápido em caso de necessidade de esclarecimentos adicionais. A informação que submeter não será usada ou distribuída por outra razão que não o processamento do seu pedido.',
            terms_conditions_required2: 'Os campos assinalados com (*) são de preenchimento obrigatório. A informação que submeter não será usada ou distribuída por outra razão que não o processamento do seu pedido.',
            testimonials:       'Testemunhos',
            text:               'Texto',
            title:              'Título',
            title_placeholder:  'Introduza o título',
            user:               'Utilizador',
            username_error_message:  'Já existe um registo com este username.',
            web_page:           'Página Web',
            cargo:              'Cargo / Empresa',
            complaint_book:     'Livro de reclamações',
            legal_info:         'Informação Legal',
            source_text:        'Fonte: ',
            source_image:       'Foto: ', 
            keep_reading:         'Continue a ler',
            cv:                 'Curriculum Vitae',
            partners:         'Parceiros', 
            projeto_cofinanciado: 'Projeto cofinanciado UE: CENTRO-04-38D7-FEDER-001180',
        },
    'en':
        {
            abstract:           'Abstract',
            accept_terms:       'I accept the terms and conditions.',
            access_data:        'Access data',
            adress:             'Address',
            agree_terms_conditions:   'I agree to ',
            all_news:           'All news',
            all_insights:       'Insights',
            article:            'Article',
            articles_submission:'Article Submission',
            author_regist:      'Author regist', 
            authors:            'Authors',
            app_form_title:     'Formulário de Inscrição Concurso de Ideias',
            app_swal_title:     'Formulário de Inscrição',
            app_form_title_01:  'Identificação do(s) promotor(es)',
            app_form_title_02:  'Setor de atividade',
            app_form_option_02_01:  'Turismo',
            app_form_option_02_02:  'Desporto',
            app_form_option_02_03:  'Serviços',
            app_form_option_02_04:  'Saúde',
            app_form_option_02_05:  'Comércio',
            app_form_option_02_06:  'Indústria',
            app_form_option_02_07:  'Ambiente',
            app_form_option_02_08:  'Energia',
            app_form_option_02_09:  'Agroalimentar',
            app_form_option_02_10:  'Agricultura',
            app_form_title_03:  'Fase de maturidade do negócio',
            app_form_option_03_01:  'Ideia',
            app_form_option_03_02:  'Pré-projeto',
            app_form_option_03_03:  'Projeto',
            app_form_option_03_04:  'Protótipo',
            app_form_title_04:  'Breve descrição da ideia/projeto',
            app_form_title_05:  'Problema(s) de que pretende resolver',
            app_form_title_06:  'Histórico do(s) promotor(es)',
            app_form_title_07:  'Qual a solução?',
            app_form_title_08:  'Quais os aspetos diferenciadores?',
            app_form_title_09:  'Caracterização do mercado',
            app_form_title_10:  'Descrição da estratégia de desenvolvimento da empresa',
            app_form_title_11:  'Descrição de fatores críticos de sucesso do negócio',
            app_form_title_12:  'Quais os parceiros?',
            app_form_title_13:  'Descrição do investimento',
            app_form_title_14:  'Previsões',
            back:               'Go back',
            banner_submission:  'Submit your articles',
            banner_submission_2:  'Learn about the next submission periods and all the necessary information.',
            banner_article_submission_text:  'To submit your articles login/register and join us!',
            bibliography:       'Bibliography',
            biography:          'Biography',
            btn_banner:         'Get to know us',
            btn_mote:           'Find out about the offers',
            btn_serra:          'Discover Serra da Estrela',
            btn_news:           'All the news',
            btn_insight:        'All the insights',
            btn_insights:       'Insights',
            btn_testimony:      'Leave your testimony',
            btn_subscribe:      'Subscrever',
            call_landline:      '(Call to the national landline network)',
            call_mobile:        '(Call to the national mobile network)',
            cancel:             'Cancel',
            cfp_status_closed:  'closed',
            cfp_status_ending:  'ending',
            cfp_status_open:    'open',
            cfp_status_soon:    'soon',
            change_password_expiration_message:     'The code to change your password has expired, please make a new request.',
            change_password_sucess_message:         'Password updated successfully',
            close:              'Close',
            code:               'code',
            confirmation_password_error_message:    'Confirmation password different from the password entered',
            contest:            'Contest',
            contacts:           'Contacts', 
            contact:            'Contact', 
            contact_email:      'Contact Email', 
            contact_form:       'Contact form', 
            contact_recovery:   'Recovery form',
            careers_form:       'Application form',
            content:            'Content',
            content_edit:       'Edit content',
            content_new:        'New content',
            content_list:       'Content list',
            cookies_message:    '<strong>ATTENTION:</strong> This site uses cookies. By using this site, you agree to the use of cookies.',
            cookie_more:        'More information.',
            cookie_button_text : 'Accept all',
            country:            'Country',
            dates:              'Dates',
            date_creation:      'Creation',
            date_publication:   'Publication',
            date_last_update:   'Last update',
            delete:             'Delete',
            delete_title:       'Delete Regist',
            delete_text:        'Are you sure you want to continue?',
            delete_confirm:     'Yes',
            delete_cancel:      'No',
            delete_error:       'Problem deleting the element!',
            delete_success:     'Element deleted successfully!',
            description:        'Description',
            developed:          'Developed by',
            discover:           'Discover',
            download:           'Descarregar',
            download_roteiro:   'Descarregar Roteiro',
            download_full_edition:           'Descarregar edição completa',
            designation:        'Name of the idea/project',
            edit:               'Edit',
            edit_title:         'Edit Regist',
            edit_text:          'Are you sure you want to continue?',
            edit_confirm:       'Yes',
            edit_cancel:        'No',
            edit_error:         'Problem updating element!',
            edit_success:       'Successful updated element!',
            edition:            'Edition',
            email:              'Email',
            email_02:           'Email do responsável',
            email_contact:      ', thank you for your contact!<br>We will get back to you as soon as possible.',
            email_careers:      'We inform you that we have received your application, we will analyse it and get back to you as soon as possible.',
            email_contest_02:   'Obrigada pela tua participação.',
            email_placeholder:  'Insert the email',
            end:                'End',
            enter_country_residence:  'Enter country of residence',
            entrepreneur_information:   'Entrepreneur support information',
            file_attach:        'Attach file',
            file_remove_all:    'Remove all',
            file_upload:        'Upload file',
            file_upload_all:    'Upload all',
            files:              'File(s)',
            filter_placeholder: 'Search',
            footer_address:     'Rua Dr. António Plácido da Costa, 16 c/v, 6200-051 Covilhã<p></p>Tfl (+351) 275 310 651/2<p></p>Fax (+351) 275 310 659',

            footer_address_big:   'Turistrela - Turismo Serra da Estrela',
            footer_address_1:   'Turistrela',
            footer_address_11:   'Estrada Nacional 339<br>Apartado 332<br>6200-073 Covilhã',
            footer_address_12:   '(+351) 275 094 779',
            footer_address_13:   'comercial@turistrela.pt',
            footer_address_14:   'Nº de registo: 9/2003',
            footer_social:       'Stay tuned',
            footer_logos_01:    'Cofounded by:',
            footer_logos_02:    'Promoted by:',
            form_autor_register: 'Author registration form',
            full_edition:       'Full edition',
            gallery_view:       'Photo gallery',
            go_platform:        'Go to the platform',
            hello:              'Hello',
            id:                 'ID',
            image:              'Image',
            image_choose:       'Choose imagem',
            info_banner_text:   'All the information you need to get started',
            insert_title:       'New Regist',
            insert_text:        'Are you sure you want to continue?',
            insert_confirm:     'Yes',
            insert_cancel:      'No',
            insert_error:       'Problem updating element!',
            insert_success:     'Successful updated element!',
            learn_more:         'Learn more',
            location:           'Location',
            login:              'Login',
            maximum_numbers_of_caracters:   'Maximum number of characters',
            message:            'Message',
            message_placeholder:'Insert the message',
            more:               'More info:',
            multimedia:         'Multimedia',
            name:               'Name',
            name_placeholder:   'Insert the name',
            news_turistrela:    'Turistrela News ',
            galleries_turistrela : 'Turistrela Galleries',
            insights_turistrela:    'Turistrela Insights ',
            partners_turistrela:    'Turistrela Partners ',
            newsletter:         'Get our Newsletter',
            newsletter_banner_text: 'Receive information about Turistrela - Turismo Serra da Estrela.',
            not_found:          'Home',
            not_found1:          'Oops! Nothing was found',
            not_found2:          'The page you are looking for might have been removed, had its name changed or is temporarily unavailable. ',
            not_found3:          'Return to homepage.',
            observations:       'Observations',
            
            opinion_banner_button:   'Leave a comment',
            opinion_banner_title:   'Let us know what you think',
            opinion_banner_text:    'These testimonials were given to us of their free will, confirming the availability and the confidence that we give to our clients and partners.',
            organization:       'Organization',
            open_file:          'Open file',
            opinion_form:       'Opinion form',
            page_config:        'Page config',
            page_parent:        'Parent',
            pages:              'Pages',
            password:           'Password',
            password_new:       'New password',
            password_conf:      'Password confirmation',
            platforms:          'Platforms',
            platforms_banner_text:  'Get to know the platforms we have for you',
            principal:          'Principal',
            privacy_policy:     'Privacy Policy',
            profile_image:      'Profile image',
            project:            'Project',
            project_banner_text:    'Find out about our entire project',
            read_article:       'Read article',
            read_terms_conditions: 'Please read our terms and conditions before submitting the form.', 
            regist:             'Register',
            regist_form_sucess_message: 'Registration successful. Check your access data in the email sent.',
            register:           'Register',
            registry_defined:   'Defined in the registry',
            remove:             'Remove',
            reserved_rights:    'All rights reserved',
            reviewers:          'Reviewers',  
            routes_turistrela:  'Roteiros Turistrela',
            // partners_turistrela:    'Parceiros de Negócios',
            content_preview:    'This is a Preview!',
            careers_turistrela: 'Careers',

            //rules:              'Rules',
            save:               'Save', 
            see_new:            'Read news',   
            see_more:           'See more',
            see_partner:        'Meet our Partner',
            scientia_magazine:  'Scientia Magazine',
            search:             'Search',
            search_by_article_name:             'Search by article name', 
            search_by_author_name:             'Search by author name', 
            search_by_article_keyword:             'Search by keywords', 
            search_error_message:             'Fill in one of the search fields.',
            search_error_noresults_message:             'No results found.',
            send:               'Send',
            sent_successfully:  'Sent successfully',
            seo_config:         'SEO config',
            seo_description:    'SEO description',
            seo_description_placeholder: 'Insert the SEO description',
            seo_keywords:       'SEO keywords',
            seo_keywords_small: '(separate by comma)',
            seo_keywords_placeholder: 'Insert the SEO keywords',
            seo_title:          'SEO title',
            seo_title_placeholder: 'Insert the SEO title',
            slide_title:        'Together we share knowledge',
            start:              'Start',
            subject:            'Subject',
            submission:         'Submission',
            submission_rules:   'Submission rules',
            submission_rules_pdf:   'Download the instructions',
            submission_rules_pdf_view:   'See submission rules',
            submit:             'Submit',
            submit_article:     'Submit article',
            surname:            'Surname',
            table_rows_per_page:'Regist by page:',
            table_separator:    'of',
            table_all:          'All',
            table_title:        'Title',
            table_parent:       'Parent',
            table_date:         'Date',
            table_state:        'State',
            table_actions:      'Actions',
            text:               'Text',
            title:              'Title',
            title_placeholder:  'Insert title',
            terms_conditions:   'Terms and conditions',
            terms_conditions_link:   'terms and conditions.',
            terms_conditions_required: 'Fields marked with (*) are required. The email address allows a faster contact in case of need for further clarifications. The information you submit will not be used or distributed for any reason other than the processing of your request.',
            terms_conditions_required2: 'Fields marked with (*) are required. The information you submit will not be used or distributed for any reason other than the processing of your request.',
            testimonials:       'Testimonials',
            technical_team:     'Technical team',
            user:               'User',
            username_error_message:  'There is already a record with this username.',
            web_page:           'Web page',
            cargo:              'Position / Company',
            complaint_book:     'Complaint Book',
            legal_info:         'Legal Notice',
            source_text:        'Source',
            source_image:       'Photo:',
            keep_reading:       'Read more',
            cv:                 'Curriculum Vitae',
            partners:           'Partners', 
            projeto_cofinanciado: 'EU co-financed project: CENTRO-04-38D7-FEDER-001180',

        },
    'es': 
        {
            abstract:           'Resumo', 
            accept_terms:       'Aceito os termos e condições.',
            access_data:        'Dados de acesso',
            adress:             'Morada',
            agree_terms_conditions: 'Estoy de acuerdo con los ', 
            all_news:           'Todas as notícias',
            all_insights:       'Insights',
            article:            'Artigo',
            articles_submission:'Submissão de artigos',
            author_regist:      'Registo de autor', 
            authors:            'Autores',  
            app_form_title:     'Formulário de Inscrição Concurso de Ideias',
            app_swal_title:     'Formulário de Inscrição',
            app_form_title_01:  'Identificação do(s) promotor(es)',
            app_form_title_02:  'Setor de atividade',
            app_form_option_02_01:  'Turismo',
            app_form_option_02_02:  'Desporto',
            app_form_option_02_03:  'Serviços',
            app_form_option_02_04:  'Saúde',
            app_form_option_02_05:  'Comércio',
            app_form_option_02_06:  'Indústria',
            app_form_option_02_07:  'Ambiente',
            app_form_option_02_08:  'Energia',
            app_form_option_02_09:  'Agroalimentar',
            app_form_option_02_10:  'Agricultura',
            app_form_title_03:  'Fase de maturidade do negócio',
            app_form_option_03_01:  'Ideia',
            app_form_option_03_02:  'Pré-projeto',
            app_form_option_03_03:  'Projeto',
            app_form_option_03_04:  'Protótipo',
            app_form_title_04:  'Breve descrição da ideia/projeto',
            app_form_title_05:  'Problema(s) de que pretende resolver',
            app_form_title_06:  'Histórico do(s) promotor(es)',
            app_form_title_07:  'Qual a solução?',
            app_form_title_08:  'Quais os aspetos diferenciadores?',
            app_form_title_09:  'Caracterização do mercado',
            app_form_title_10:  'Descrição da estratégia de desenvolvimento da empresa',
            app_form_title_11:  'Descrição de fatores críticos de sucesso do negócio',
            app_form_title_12:  'Quais os parceiros?',
            app_form_title_13:  'Descrição do investimento',
            app_form_title_14:  'Previsões',
            back:               'Voltar',
            banner_submission:  'Submeta os seus artigos',
            banner_submission_2:'Conheça os próximos períodos de submissão e todas as informações necessárias.',
            banner_article_submission_text:  'Para submeter os seus artigos faça o seu login/registo e junte-se a nós!',
            bibliography:       'Bibliografia',
            biography:          'Biografia',
            btn_banner:         'Ven a conocernos',
            btn_mote:           'Conozca las ofertas',
            btn_serra:          'Descubre la Serra da Estrela',
            btn_news:           'Todas las noticias',
            btn_insight:        'Todos los insights',
            btn_insights:       'Insights',
            btn_testimony:      'Deje su testimonio',
            btn_subscribe:      'Subscrever',
            call_landline:      '(Llamada a la red fija nacional)',
            call_mobile:        '(Llamada a la red móvil nacional)',
            cancel:             'Cancelar',
            cfp_status_closed:  'fechado',
            cfp_status_ending:  'a terminar',
            cfp_status_open:    'aberto',
            cfp_status_soon:    'brevemente',
            change_password_expiration_message:    'O código para alterar a sua palavra-passe expirou, faça um novo pedido por favor.',
            change_password_sucess_message:    'Palavra-passe atualizada com sucesso',
            close:              'Fechar',
            code:               'Código',
            confirmation_password_error_message: 'Password de confirmação diferente da password inserida',
            contest:            'Concurso',
            contacts:           'Contactos',  
            contact:            'Contacto',  
            contact_email:      'Email de contacto',
            contact_form:       'Formulário de contacto',  
            contact_recovery:   'Formulário de recuperação',
            careers_form:       'Formulario de solicitud',
            content:            'Conteúdo',
            content_edit:       'Editar conteúdo',
            content_new:        'Novo conteúdo',
            content_list:       'Lista de conteúdos',
            cookies_message:    '<strong>Información sobre las cookies:</strong> Este sitio utiliza cookies. Al navegar por el sitio, usted acepta su uso.',
            cookie_more:        'Más información.',
            cookie_button_text : 'Aceptar todas',
            country:            'País',
            dates:              'Datas',
            date_creation:      'Criação',
            date_publication:   'Publicação',
            date_last_update:   'Última actualização',
            delete:             'Eliminar',
            delete_title:       'Eliminar Registo',
            delete_text:        'Tem a certeza que pretende continuar?',
            delete_confirm:     'Sim',
            delete_cancel:      'Não',
            delete_error:       'Problema ao apagar registo!',
            delete_success:     'Registo apagado com sucesso!',
            description:        'Descrição',
            developed:          'Desarrollado por',
            discover:           'Descobrir',
            download:           'Descarregar',
            download_roteiro:   'Descarregar Roteiro',
            download_full_edition:           'Descarregar edição completa',
            designation:        'Designação da ideia/projeto',
            edit:               'Editar',
            edit_title:         'Editar Registo',
            edit_text:          'Tem a certeza que pretende continuar?',
            edit_confirm:       'Sim',
            edit_cancel:        'Não',
            edit_error:         'Problema ao actualizar registo!',
            edit_success:       'Registo actualizado com sucesso!',
            edition:            'Edição',
            email:              'Email',
            email_02:           'Email do responsável',
            email_contact:      ', gracias por su contacto.<br>Nos pondremos en contacto con usted lo antes posible.',
            email_careers:      'Le informamos de que hemos recibido su solicitud, la analizaremos y nos pondremos en contacto con usted lo antes posible.',
            email_contest_02:   'Obrigada pela tua participação.',
            email_placeholder:  'Introduza o email',
            end:                'Fim',
            enter_country_residence:  'Introduza o país de residência',
            entrepreneur_information:   'Informação de Apoio ao Empreendedor',
            file_attach:        'Attach file',
            file_remove_all:    'Remove all',
            file_upload:        'Upload file',
            file_upload_all:    'Upload all',
            files:              'Ficheiro(s)',
            filter_placeholder: 'Procurar',
            footer_address:     'Rua Dr. António Plácido da Costa, 16 c/v, 6200-051 Covilhã<p></p>Tfl (+351) 275 310 651/2<p></p>Fax (+351) 275 310 659',

            footer_address_big:   'Turistrela - Turismo Serra da Estrela',
            footer_address_1:   'Turistrela',
            footer_address_11:   'Estrada Nacional 339<br>Apartado 332<br>6200-073 Covilhã',
            footer_address_12:   '(+351) 275 094 779',
            footer_address_13:   'comercial@turistrela.pt',
            footer_address_14:   'Nº de registo: 9/2003',
            footer_social:       'Manténgase al corriente',
            footer_logos_01:    'Cofinanciado por:',
            footer_logos_02:    'Promovido por:',
            footer_logos_03:    'Redes Sociais:',
            form_autor_register: 'Formulário de registo de autor',
            full_edition:       'Edição completa',
            go_platform:        'Ir para a plataforma',
            hello:              'Olá',
            id:                 'ID',
            image:              'Imagem',
            image_choose:       'Escolher imagem',
            info_banner_text:   'Todas as informações necessárias para começar',
            insert_title:       'Novo Registo',
            insert_text:        'Tem a certeza que pretende continuar?',
            insert_confirm:     'Sim',
            insert_cancel:      'Não',
            insert_error:       'Problema ao inserir registo!',
            insert_success:     'Registo inserido com sucesso!',
            learn_more:         'Saiba mais',
            location:           'Localização',
            login:              'Iniciar sessão',
            name:               'Nombre',
            name_placeholder:   'Introduza o nome',
            news_turistrela:    'Noticias Turistrela',
            galleries_turistrela : 'Galerías Turistrela',
            insights_turistrela:    'Insights Turistrela',
            partners_turistrela:    'Socios Comerciales',
            newsletter:         'Receba a nossa Newsletter',
            newsletter_banner_text: 'Receba informação sobre a Turistrela - Turismo Serra da Estrela.',
            maximum_numbers_of_caracters:   'Número máximo de caracteres',
            message:            'Mensaje',
            message_placeholder:    'Introduza a mensagem',
            more:               'Mais informação:',
            multimedia:         'Multimédia',
            observations:       'Observações',
            opinion_banner_button:   'Deixar opinião',
            opinion_banner_title:   'Deixe-nos a sua opinião',
            opinion_banner_text:    'Estos testimonios nos fueron proporcionados gratuitamente, lo que confirma la disponibilidad y la confianza que brindamos a nuestros clientes y socios.',
            organization:       'Organização',
            open_file:          'Ver ficheiro',
            opinion_form:       'Formulario de opinión',
            page_config:        'Configuração da Página',
            page_parent:        'Parent',
            pages:              'Páginas',
            password:           'Palavra-passe',
            password_new:       'Nova palavra-passe',
            password_conf:      'Confirmação da palavra-passe',
            platforms:          'Plataformas',
            platforms_banner_text:  'Conheça as nossas plataformas que temos para si',
            principal:          'Principal',
            privacy_policy:     'Política de privacidade',
            profile_image:      'Imagem de perfil',
            project:            'Projeto',
            project_banner_text:    'Fique a conhecer todo o nosso projeto',
            read_article:       'Ler artigo',
            read_terms_conditions: 'Lea nuestras condiciones generales antes de enviar el formulario.', 
            regist:             'Registo',
            regist_form_sucess_message: 'Registo efetuado com sucesso. Consulte os seus dados de acesso no email enviado.',
            register:           'Registe-se',
            registry_defined:   'Definida no registo',
            remove:             'Remover',
            reserved_rights:    'Todos los direichos reservados',
            reviewers:          'Revisores',
            routes_turistrela:  'Roteiros Turistrela',
            // partners_turistrela:'Parceiros de Negócios',
            content_preview:    '¡Esto es una previsualización!',
            careers_turistrela: 'Careers',

            //rules:              'Normas de submissão',
            save:               'Salvar', 
            see_more:           'Ver mais',
            see_partner:        'Conozca a nuestro Socio',
            scientia_magazine:  'Revista Científica',
            search:             'Pesquisar', 
            search_by_article_name:             'Pesquisa por nome de artigo', 
            search_by_author_name:             'Pesquisa por nome de autor', 
            search_by_article_keyword:             'Pesquisa por keywords', 
            search_error_message:             'Preencha um dos campos de pesquisa.',
            search_error_noresults_message:             'Não foi encontrado nenhum resultado.',
            send:               'Enviar',   
            sent_successfully:  'Enviado com sucesso',
            seo_config:         'Configuração SEO',
            seo_description:    'Descrição SEO',
            seo_description_placeholder: 'Introduza a descrição SEO',
            seo_keywords:       'Keywords SEO',
            seo_keywords_small: '(separar por virgula)',
            seo_keywords_placeholder: 'Introduza as keywords SEO',
            seo_title:          'Título SEO',
            seo_title_placeholder: 'Introduza o título SEO',
            subject:            'Sujeto',
            slide_title:        'Juntos partilhamos conhecimento',
            start:              'Início',
            submission:         'Submissão',
            submission_rules:   'Normas de submissão',
            submission_rules_pdf:   'Descarregar instruções',
            submission_rules_pdf_view:   'Ver normas de submissão',
            submit:             'Submeter',
            submit_article:     'Submeter artigo',
            surname:            'Apelido',
            table_rows_per_page:'Registos por página:',
            table_separator:    'de',
            table_all:          'Todos',
            table_title:        'Título',
            table_parent:       'Parent',
            table_date:         'Data',
            table_state:        'Estado',
            table_actions:      'Acções',
            technical_team:     'Corpo técnico',
            terms_conditions:   'Términos y condiciones',          
            terms_conditions_link:   'términos y condiciones.',
            terms_conditions_required: 'Los campos marcados con (*) son obligatorios. La dirección de correo electrónico permite un contacto más rápido en caso de necesitar más aclaraciones. La información que envíe no se utilizará ni distribuirá por ningún motivo que no sea la tramitación de su solicitud.',
            terms_conditions_required2: 'Los campos marcados con (*) son obligatorios. La información que envíe no se utilizará ni distribuirá por ningún motivo que no sea la tramitación de su solicitud.',
            testimonials:       'Testimonios',
            text:               'Texto',
            title:              'Título',
            title_placeholder:  'Introduza o título',
            user:               'Utilizador',
            username_error_message:  'Já existe um registo com este utilizador.',
            web_page:           'Página Web',
            cargo:              'Cargo / Empresa',
            complaint_book:     'Libro de reclamaciones',
            legal_info:         'Informaciones legales',
            source_text:        'Source: ',
            source_image:       'Foto:',
            keep_reading:       'Sigue leyendo ',
            cv:                 'Curriculum Vitae',
            partners:           'Socios', 
            projeto_cofinanciado: 'Proyecto cofinanciado por la UE: CENTRO-04-38D7-FEDER-001180',
        },
    'fr':
        {
            abstract:           'Abstract',
            accept_terms:       'I accept the terms and conditions.',
            access_data:        'Access data',
            adress:             'Address',
            agree_terms_conditions:   'Je suis d\'accord avec les ',
            all_news:           'All news',
            all_insights:       'Insights',
            article:            'Article',
            articles_submission:'Article Submission',
            author_regist:      'Author regist', 
            authors:            'Authors',
            app_form_title:     'Formulário de Inscrição Concurso de Ideias',
            app_swal_title:     'Formulário de Inscrição',
            app_form_title_01:  'Identificação do(s) promotor(es)',
            app_form_title_02:  'Setor de atividade',
            app_form_option_02_01:  'Turismo',
            app_form_option_02_02:  'Desporto',
            app_form_option_02_03:  'Serviços',
            app_form_option_02_04:  'Saúde',
            app_form_option_02_05:  'Comércio',
            app_form_option_02_06:  'Indústria',
            app_form_option_02_07:  'Ambiente',
            app_form_option_02_08:  'Energia',
            app_form_option_02_09:  'Agroalimentar',
            app_form_option_02_10:  'Agricultura',
            app_form_title_03:  'Fase de maturidade do negócio',
            app_form_option_03_01:  'Ideia',
            app_form_option_03_02:  'Pré-projeto',
            app_form_option_03_03:  'Projeto',
            app_form_option_03_04:  'Protótipo',
            app_form_title_04:  'Breve descrição da ideia/projeto',
            app_form_title_05:  'Problema(s) de que pretende resolver',
            app_form_title_06:  'Histórico do(s) promotor(es)',
            app_form_title_07:  'Qual a solução?',
            app_form_title_08:  'Quais os aspetos diferenciadores?',
            app_form_title_09:  'Caracterização do mercado',
            app_form_title_10:  'Descrição da estratégia de desenvolvimento da empresa',
            app_form_title_11:  'Descrição de fatores críticos de sucesso do negócio',
            app_form_title_12:  'Quais os parceiros?',
            app_form_title_13:  'Descrição do investimento',
            app_form_title_14:  'Previsões',
            back:               'Go back',
            banner_submission:  'Submit your articles',
            banner_submission_2:  'Learn about the next submission periods and all the necessary information.',
            banner_article_submission_text:  'To submit your articles login/register and join us!',
            bibliography:       'Bibliography',
            biography:          'Biography',
            btn_banner:         'Venez nous rencontrer',
            btn_mote:           'Consultez les offres',
            btn_serra:          'Découvrez la Serra da Estrela',
            btn_news:           'Toutes les nouvelles',
            btn_insight:        'Tous les insights',
            btn_insights:       'Insights',
            btn_testimony:      'Laissez votre témoignage',
            btn_subscribe:      'Subscrever',
            call_landline:      '(Appel vers le réseau fixe national)',
            call_mobile:        '(Appeler vers le réseau mobile national)',
            cancel:             'Annuler',
            cfp_status_closed:  'closed',
            cfp_status_ending:  'ending',
            cfp_status_open:    'open',
            cfp_status_soon:    'soon',
            change_password_expiration_message:     'The code to change your password has expired, please make a new request.',
            change_password_sucess_message:         'Password updated successfully',
            close:              'Close',
            code:               'code',
            confirmation_password_error_message:    'Confirmation password different from the password entered',
            contest:            'Contest',
            contacts:           'Contacts', 
            contact:            'Contact', 
            contact_email:      'Contact Email', 
            contact_form:       'Contact form', 
            contact_recovery:   'Recovery form',
            careers_form:       'Application form',
            content:            'Content',
            content_edit:       'Edit content',
            content_new:        'New content',
            content_list:       'Content list',
            cookies_message:    '<strong>ATTENTION :</strong> Ce site utilise des cookies. En utilisant ce site, vous acceptez l’utilisation de cookies.',
            cookie_more:        'Plus d\'information.',
            cookie_button_text : 'Accepter',
            country:            'Country',
            dates:              'Dates',
            date_creation:      'Creation',
            date_publication:   'Publication',
            date_last_update:   'Last update',
            delete:             'Delete',
            delete_title:       'Delete Regist',
            delete_text:        'Are you sure you want to continue?',
            delete_confirm:     'Yes',
            delete_cancel:      'No',
            delete_error:       'Problem deleting the element!',
            delete_success:     'Element deleted successfully!',
            description:        'Description',
            developed:          'Développé par',
            discover:           'Discover',
            download:           'Descarregar',
            download_roteiro:   'Descarregar Roteiro',
            download_full_edition:           'Descarregar edição completa',
            designation:        'Name of the idea/project',
            edit:               'Edit',
            edit_title:         'Edit Regist',
            edit_text:          'Are you sure you want to continue?',
            edit_confirm:       'Yes',
            edit_cancel:        'No',
            edit_error:         'Problem updating element!',
            edit_success:       'Successful updated element!',
            edition:            'Edition',
            email:              'Email',
            email_02:           'Email do responsável',
            email_contact:      ', thank you for your contact!<br>We will get back to you as soon as possible.',
            email_careers:      'We inform you that we have received your application, we will analyse it and get back to you as soon as possible.',
            email_contest_02:   'Obrigada pela tua participação.',
            email_placeholder:  'Insert the email',
            end:                'End',
            enter_country_residence:  'Enter country of residence',
            entrepreneur_information:   'Entrepreneur support information',
            file_attach:        'Attach file',
            file_remove_all:    'Remove all',
            file_upload:        'Upload file',
            file_upload_all:    'Upload all',
            files:              'File(s)',
            filter_placeholder: 'Search',
            footer_address:     'Rua Dr. António Plácido da Costa, 16 c/v, 6200-051 Covilhã<p></p>Tfl (+351) 275 310 651/2<p></p>Fax (+351) 275 310 659',

            footer_address_big:   'Turistrela - Turismo Serra da Estrela',
            footer_address_1:   'Turistrela',
            footer_address_11:   'Estrada Nacional 339<br>Apartado 332<br>6200-073 Covilhã',
            footer_address_12:   '(+351) 275 094 779',
            footer_address_13:   'comercial@turistrela.pt',
            footer_address_14:   'Nº de registo: 9/2003',
            footer_social:       'Rester à l\'écoute',
            footer_logos_01:    'Cofounded by:',
            footer_logos_02:    'Promoted by:',
            form_autor_register: 'Author registration form',
            full_edition:       'Full edition',
            gallery_view:       'Photo gallery',
            go_platform:        'Go to the platform',
            hello:              'Hello',
            id:                 'ID',
            image:              'Image',
            image_choose:       'Choose imagem',
            info_banner_text:   'All the information you need to get started',
            insert_title:       'New Regist',
            insert_text:        'Are you sure you want to continue?',
            insert_confirm:     'Yes',
            insert_cancel:      'No',
            insert_error:       'Problem updating element!',
            insert_success:     'Successful updated element!',
            learn_more:         'Learn more',
            location:           'Location',
            login:              'Login',
            maximum_numbers_of_caracters:   'Nombre maximum de caractères',
            message:            'Message',
            message_placeholder:'Insert the message',
            more:               'More info:',
            multimedia:         'Multimedia',
            name:               'Nom',
            name_placeholder:   'Insert the name',
            news_turistrela:    'Turistrela Nouvelles ',
            galleries_turistrela : 'Galeries Turistrela',
            insights_turistrela:    'Turistrela Insights ',
            partners_turistrela:    'Turistrela Partners ',
            newsletter:         'Get our Newsletter',
            newsletter_banner_text: 'Receive information about Turistrela - Turismo Serra da Estrela.',
            not_found:          'Home',
            not_found1:          'Oops! Nothing was found',
            not_found2:          'The page you are looking for might have been removed, had its name changed or is temporarily unavailable. ',
            not_found3:          'Return to homepage.',
            observations:       'Observations',
            
            opinion_banner_button:   'Leave a comment',
            opinion_banner_title:   'Let us know what you think',
            opinion_banner_text:    'Ces témoignages nous ont été remis librement, confirmant la disponibilité et la confiance que nous accordons à nos clients et partenaires.',
            organization:       'Organization',
            open_file:          'Open file',
            opinion_form:       'Formulaire d\'opinion',
            page_config:        'Page config',
            page_parent:        'Parent',
            pages:              'Pages',
            password:           'Password',
            password_new:       'New password',
            password_conf:      'Password confirmation',
            platforms:          'Platforms',
            platforms_banner_text:  'Get to know the platforms we have for you',
            principal:          'Principal',
            privacy_policy:     'Privacy Policy',
            profile_image:      'Profile image',
            project:            'Project',
            project_banner_text:    'Find out about our entire project',
            read_article:       'Read article',
            read_terms_conditions: 'Veuillez lire nos conditions générales avant de soumettre le formulaire.', 
            regist:             'Register',
            regist_form_sucess_message: 'Registration successful. Check your access data in the email sent.',
            register:           'Register',
            registry_defined:   'Defined in the registry',
            remove:             'Remove',
            reserved_rights:    'Tous droits réservés',
            reviewers:          'Reviewers',  
            routes_turistrela:  'Roteiros Turistrela',
            // partners_turistrela:    'Parceiros de Negócios',
            content_preview:    'This is a Preview!',
            careers_turistrela: 'Careers',

            //rules:              'Rules',
            save:               'Save', 
            see_new:            'Read news',   
            see_more:           'See more',
            see_partner:        'Meet our Partner',
            scientia_magazine:  'Scientia Magazine',
            search:             'Search',
            search_by_article_name:             'Search by article name', 
            search_by_author_name:             'Search by author name', 
            search_by_article_keyword:             'Search by keywords', 
            search_error_message:             'Fill in one of the search fields.',
            search_error_noresults_message:             'No results found.',
            send:               'Envoyer',
            sent_successfully:  'Sent successfully',
            seo_config:         'SEO config',
            seo_description:    'SEO description',
            seo_description_placeholder: 'Insert the SEO description',
            seo_keywords:       'SEO keywords',
            seo_keywords_small: '(separate by comma)',
            seo_keywords_placeholder: 'Insert the SEO keywords',
            seo_title:          'SEO title',
            seo_title_placeholder: 'Insert the SEO title',
            slide_title:        'Together we share knowledge',
            start:              'Start',
            subject:            'Sujet',
            submission:         'Submission',
            submission_rules:   'Submission rules',
            submission_rules_pdf:   'Download the instructions',
            submission_rules_pdf_view:   'See submission rules',
            submit:             'Submit',
            submit_article:     'Submit article',
            surname:            'Surname',
            table_rows_per_page:'Regist by page:',
            table_separator:    'of',
            table_all:          'All',
            table_title:        'Title',
            table_parent:       'Parent',
            table_date:         'Date',
            table_state:        'State',
            table_actions:      'Actions',
            text:               'Text',
            title:              'Title',
            title_placeholder:  'Insert title',
            terms_conditions:   'Conditions générales',
            terms_conditions_link:   'conditions générales.',
            terms_conditions_required: 'Les champs marqués d\'un astérisque (*) sont obligatoires. L\'adresse électronique vous permet de nous contacter plus rapidement si vous avez besoin de précisions. Les informations que vous nous communiquez ne seront pas utilisées ou diffusées à d\'autres fins que le traitement de votre demande.',
            terms_conditions_required2: 'Les champs marqué d\'une étoile sont obligatoires. Les informations que vous soumettez ne seront ni utilisées ni distribuées pour aucune raison autre que le traitement de votre demande.',
            testimonials:       'Témoignages',
            technical_team:     'Technical team',
            user:               'User',
            username_error_message:  'There is already a record with this username.',
            web_page:           'Web page',
            cargo:              'Position / Enterprise',
            complaint_book:     'Cahier de doléances',
            legal_info:         'Legal Notice',
            source_text:        'Source',
            source_image:       'Photo:',
            keep_reading:       'Read more',
            cv:                 'Curriculum Vitae',
            partners:           'Partenaires', 
            projeto_cofinanciado: 'Projet cofinancé par l\'UE: CENTRO-04-38D7-FEDER-001180',

        },
}

module.exports = {
    language
};