import React from "react";
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
} from "react-share";

const DivSharer = ({ link, description}) => {
  // console.log('link', link)
  // console.log('desc', description)
  return (
    <>
      <FacebookShareButton className='mr-2' url={link} quote={description} >
        <FacebookIcon size={32} round={true} />
      </FacebookShareButton>

      <TwitterShareButton className='mr-2' url={link} >
        <TwitterIcon size={32} round={true} />
      </TwitterShareButton>
    </>
  );
};

export default DivSharer;
