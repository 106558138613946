import React, { useState, useEffect } from 'react';

import * as Hosts from '../../../Hosts';
import { set_language, repo_logo_link, content_language } from '../../../Utils';

import Loader from '../../../loader';
import { language } from '../WEBContent/Language';

export default function Normal(props) {

    const [lang, set_lang] = useState('pt');
    const [, set_lang_id] = useState('1');

    const [isloading, setLoading] = useState(true);

    useEffect(() => {
        if (localStorage.getItem('lang') === undefined || localStorage.getItem('lang') === null || localStorage.getItem('lang') === '') {
            set_lang('pt')
            set_language('pt')
            set_lang_id('1')
        }
        else {
            set_lang(localStorage.getItem('lang'))
            content_language.forEach(el => {
                if (el.code === localStorage.getItem('lang')) {
                    set_lang_id(el.id)
                }
            })
        }

        setLoading(false) 
        
    }, []);

    return (
        isloading ?
    		<Loader />
	    :
            <>
                <div className="col-12">
                    <div id="notfound">
                        <div className="notfound">
                            <div className="404logo">
                                <img id="big-logo" src={repo_logo_link("logo.svg")} alt={Hosts.webSiteTitle} title={Hosts.webSiteTitle}
                            className="svg-logo img-fluid" />
                            </div>
                            <div className="notfound-404">
                                <h1>404</h1>
                            </div>
                            <h2>{language[lang].not_found1}</h2>
                            <p>{language[lang].not_found2}</p><p><a href="./" title="Home">{language[lang].not_found3}</a></p>
                        </div>
                    </div>
                </div>
            </>
    )
}